<template>
  <div class="news-page">
    <MenuComponent />
    <CartComponent class="cart-component" />

    <h1 class="news-title">{{ $t('news') }}</h1>

    <div v-if="newsList && newsList.length > 0" class="news-list">
      <div
        v-for="newsItem in newsList"
        :key="newsItem.id"
        class="news-item"
        @click="openModal(newsItem)"
      >
        <p class="news-date">{{ formatDate(newsItem.publishedAt) }}</p>
        <h2 class="news-heading">{{ newsItem.title_ru }}</h2>
        <p class="news-content">
          {{ truncateContent(newsItem.content_ru || '') }}
          <span v-if="(newsItem.content_ru || '').length > 200" class="read-more">ДАЛЕЕ</span>
        </p>
      </div>
    </div>
    <div v-else>
      <p>{{ $t('noNews') }}</p>
    </div>

    <NewsDetailModal
      v-if="selectedNews"
      :isVisible="isModalVisible"
      :news="selectedNews"
      @close="closeModal"
    />

    <button v-show="showScrollButton" class="scroll-to-top" @click="scrollToTop">
      <img src="@/assets/uparrow.png" alt="Scroll to top" class="uparrow-icon" />
    </button>

    <footer class="footer">
      <p>{{ $t('footerRights') }}</p>
    </footer>
  </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import CartComponent from '@/components/CartComponent.vue';
import NewsDetailModal from '@/components/NewsDetailModal.vue';
import axios from 'axios';
import throttle from 'lodash.throttle';

export default {
  components: {
    MenuComponent,
    CartComponent,
    NewsDetailModal,
  },
  data() {
    return {
      newsList: [], // Список новостей
      selectedNews: null,
      isModalVisible: false,
      showScrollButton: false,
    };
  },
  methods: {
    async fetchNews() {
      try {
        const response = await axios.get('/api/news');
        if (response.headers['content-type'] && response.headers['content-type'].includes('application/json')) {
          if (Array.isArray(response.data)) {
            this.newsList = response.data;
          } else {
            console.error('Некорректный формат данных (ожидался массив):', response.data);
            this.newsList = [];
          }
        } else {
          console.error('Ответ не в формате JSON:', response.data);
          this.newsList = [];
        }
      } catch (error) {
        console.error('Ошибка при загрузке новостей:', error);
        this.newsList = [];
      }
    },
    openModal(newsItem) {
      this.selectedNews = newsItem;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedNews = null;
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('ru-RU', options);
    },
    truncateContent(content) {
      return content.length > 200 ? content.substring(0, 200) + '...' : content;
    },
    handleScroll() {
      this.showScrollButton = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.fetchNews();
    window.addEventListener("scroll", throttle(this.handleScroll, 200));
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.news-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('@/assets/overlay.jpg'); /* Фон страницы */
  background-size: cover; /* Фоновое изображение покрывает всю страницу */
  background-position: center;
  background-attachment: fixed; /* Закрепляем фон */
  padding: 20px;
  color: #ffffff;
}

.news-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.news-item {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.news-item:hover {
  background-color: #e2e8e4;
}

.news-date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 5px;
}

.news-heading {
  font-size: 1.5rem;
  margin: 5px 0 10px;
}

.news-content {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.read-more {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.footer {
  background-color: #6eb5c0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9990;
}

.scroll-to-top {
  position: fixed;
  bottom: 80px;
  right: 40px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 99;
}

.scroll-to-top .uparrow-icon {
  width: 40px;
  height: 40px;
}

.scroll-to-top:hover .uparrow-icon {
  transform: scale(1.1);
  transition: transform 0.2s;
}

/* Мобильная версия */
@media (max-width: 768px) {
  .news-page {
    padding: 10px;
  }

  .news-title {
    font-size: 24px;
  }

  .news-item {
    padding: 15px;
  }

  .news-heading {
    font-size: 1.2rem;
  }

  .news-content {
    font-size: 0.9rem;
  }
}
</style>