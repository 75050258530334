<template>
  <div v-if="isOpen" class="modal">
    <div class="modal-content">
      <h1>Редактировать продукт</h1>
      <form @submit.prevent="handleSubmit">
        <!-- Доступность продукта -->
        <div class="form-group">
          <label>В наличии </label>
          <input type="checkbox" v-model="productData.available" />
        </div>

        <!-- Тип продукта -->
        <div class="form-group">
          <label>Тип продукта </label>
          <select v-model="productData.productType" required>
            <option value="товар">Товар</option>
            <option value="услуга">Услуга</option>
          </select>
        </div>

        <!-- Название и описание на русском -->
        <div class="form-group">
          <label>Название на русском</label>
          <input type="text" v-model="productData.nameRu" required />
        </div>
        <div class="form-group">
          <label>Описание на русском</label>
          <textarea v-model="productData.descriptionRu" required></textarea>
        </div>

        <!-- Флажок для английского языка -->
        <div class="form-group">
          <label>English</label>
          <input type="checkbox" v-model="productData.hasEnglish" />
        </div>

        <!-- Название и описание на английском -->
        <div v-if="productData.hasEnglish">
          <div class="form-group">
            <label>Name </label>
            <input type="text" v-model="productData.nameEn" />
          </div>
          <div class="form-group">
            <label>Description </label>
            <textarea v-model="productData.descriptionEn"></textarea>
          </div>
        </div>

        <!-- Цена -->
        <div class="form-group">
          <label>Цена: </label>
          <input type="number" v-model="productData.priceTHB" required />
          <label> ฿ </label>
        </div>

        <!-- Категории -->
        <div class="form-group">
          <div class="categories">
            <label><input type="checkbox" v-model="productData.vehicles" /> Транспортные средства</label>
            <label><input type="checkbox" v-model="productData.realestate" /> Недвижимость</label>
            <label><input type="checkbox" v-model="productData.business" /> Бизнес</label>
            <label><input type="checkbox" v-model="productData.tours" /> Экскурсии</label>
            <label><input type="checkbox" v-model="productData.entertainment" /> Развлечения</label>
            <label><input type="checkbox" v-model="productData.music_equipment" /> Музыкальное оборудование</label>
            <label><input type="checkbox" v-model="productData.digital" /> Цифровые товары</label>
            <label><input type="checkbox" v-model="productData.different" /> Разное</label>
          </div>
        </div>

        <!-- Ссылки для контактов -->
        <div class="form-group">
          <input type="text" v-model="productData.linkWhatsApp" placeholder="WhatsApp" />
          <input type="text" v-model="productData.linkTelegram" placeholder="Telegram" />
          <input type="text" v-model="productData.linkLine" placeholder="Line" />
          <input type="text" v-model="productData.linkEmail" placeholder="Email" />
          <input type="text" v-model="productData.linkCustom" placeholder="Другая ссылка" />
        </div>

        <!-- Кнопки -->
        <div class="form-group">
          <button type="submit">Обновить продукт</button>
          <button type="button" @click="$emit('close')">Закрыть</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productData: { ...this.product, photos: [...this.product.photos] },
      removedPhotos: [],
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const formData = new FormData();

        formData.append('available', this.productData.available);
        formData.append('productType', this.productData.productType);
        formData.append('hasEnglish', this.productData.hasEnglish);
        formData.append('nameRu', this.productData.nameRu);
        formData.append('descriptionRu', this.productData.descriptionRu);
        formData.append('nameEn', this.productData.nameEn || '');
        formData.append('descriptionEn', this.productData.descriptionEn || '');
        formData.append('priceTHB', this.productData.priceTHB);

        formData.append('vehicles', this.productData.vehicles === true ? 'true' : 'false');
        formData.append('realestate', this.productData.realestate === true ? 'true' : 'false');
        formData.append('business', this.productData.business === true ? 'true' : 'false');
        formData.append('tours', this.productData.tours === true ? 'true' : 'false');
        formData.append('entertainment', this.productData.entertainment === true ? 'true' : 'false');
        formData.append('music_equipment', this.productData.music_equipment === true ? 'true' : 'false');
        formData.append('digital', this.productData.digital === true ? 'true' : 'false');
        formData.append('different', this.productData.different === true ? 'true' : 'false');

        formData.append('linkWhatsApp', this.productData.linkWhatsApp || '');
        formData.append('linkTelegram', this.productData.linkTelegram || '');
        formData.append('linkLine', this.productData.linkLine || '');
        formData.append('linkEmail', this.productData.linkEmail || '');
        formData.append('linkCustom', this.productData.linkCustom || '');

        this.productData.photos.forEach(photo => {
          if (typeof photo === 'string') {
            formData.append('photos', photo);
          } else if (photo instanceof File) {
            formData.append('photos', photo);
          }
        });

        if (this.removedPhotos.length > 0) {
          formData.append('removedPhotos', JSON.stringify(this.removedPhotos));
        }

        // Убираем логирование
        await this.$axios.put(`/api/products/${this.product.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.$emit('save');
      } catch (error) {
        // Обработка ошибок, если требуется
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #121212;
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  max-height: 80vh;
  overflow-y: auto;
}

.form-group {
  margin-bottom: 20px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
}

.categories label {
  width: 50%;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}

button:hover {
  background-color: #45a049;
}
</style>