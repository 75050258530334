<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <div class="service-modal">
        <!-- Название услуги -->
        <h1 class="service-name">{{ serviceName }}</h1>

        <!-- Строка с ценой и ссылками для связи -->
        <div class="service-actions">
          <div class="price-group">
            <p class="price-label">{{ $t('from') }} {{ servicePrice }} {{ selectedCurrencySymbol }}</p>
          </div>
          <div class="contact-links-group">
            <p>{{ $t('contactManager') }}&nbsp;</p>
            <div class="icons">
              <a v-if="service.linkWhatsApp" :href="service.linkWhatsApp" target="_blank">
                <img src="@/assets/whatsapp.png" alt="WhatsApp" />
              </a>
              <a v-if="service.linkTelegram" :href="service.linkTelegram" target="_blank">
                <img src="@/assets/telegram.png" alt="Telegram" />
              </a>
              <a v-if="service.linkLine" :href="service.linkLine" target="_blank">
                <img src="@/assets/line.png" alt="Line" />
              </a>
              <a v-if="service.linkEmail" :href="'mailto:' + service.linkEmail" target="_blank">
                <img src="@/assets/email.png" alt="Email" />
              </a>
              <a v-if="service.linkCustom" :href="service.linkCustom" target="_blank">
                <img src="@/assets/link.png" alt="Custom Link" />
              </a>
            </div>
          </div>
        </div>

        <!-- Описание услуги с рендерингом HTML для новых строк -->
        <p class="service-description" v-html="serviceDescription"></p>

        <!-- Карусель изображений -->
        <div class="service-images" @click="handleImageClick">
          <div v-if="service.photos && service.photos.length > 0" class="carousel">
            <img
              :src="getFullImagePath(service.photos[currentImageIndex])"
              alt="Service Image"
              class="service-image"
            />
            <!-- Точки для отображения текущей фотографии -->
            <div class="carousel-indicators">
              <span
                v-for="(photo, index) in service.photos"
                :key="index"
                :class="{'active': currentImageIndex === index}"
                class="indicator-dot"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceModal',
  props: {
    serviceId: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      service: {},
      selectedCurrency: localStorage.getItem('selectedCurrency') || 'THB',
      selectedCurrencySymbol: '฿',
      currentImageIndex: 0,
      exchangeRates: {},
    };
  },
  computed: {
    serviceName() {
      const language = localStorage.getItem('selectedLanguage') || 'ru';
      return this.service ? (language === 'ru' ? this.service.nameRu : this.service.nameEn) : 'Unknown Service';
    },
    serviceDescription() {
      const language = localStorage.getItem('selectedLanguage') || 'ru';
      const description = language === 'ru' ? this.service.descriptionRu : this.service.descriptionEn;
      return description ? description.replace(/\n/g, '<br>') : '';
    },
    servicePrice() {
      const rate = this.exchangeRates[this.selectedCurrency] || 1;
      return this.service.priceTHB ? Math.round(this.service.priceTHB * rate) : 0;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    handleImageClick(event) {
      const clickX = event.clientX;
      const imageWidth = event.target.clientWidth;
      if (clickX < imageWidth / 2) {
        this.prevImage();
      } else {
        this.nextImage();
      }
    },
    async fetchService(id) {
      try {
        const response = await this.$axios.get(`/api/products/${id}`);
        this.service = response.data;
      } catch (error) {
        console.error('Ошибка при загрузке услуги:', error);
      }
    },
    async fetchExchangeRates() {
      try {
        const response = await this.$axios.get('/api/currency');
        this.exchangeRates = response.data.rates;
        this.selectedCurrencySymbol = this.getCurrencySymbol(this.selectedCurrency);
      } catch (error) {
        console.error('Ошибка при загрузке курсов валют:', error);
      }
    },
    getCurrencySymbol(currency) {
      switch (currency) {
        case 'RUB':
          return '₽';
        case 'USDT':
          return '₮';
        default:
          return '฿';
      }
    },
    getFullImagePath(photoPath) {
      const baseUrl = process.env.VUE_APP_BASE_URL || '';
      return photoPath ? `${baseUrl}${photoPath}` : 'default-image.jpg';
    },
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.service.photos.length;
    },
    prevImage() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.service.photos.length) % this.service.photos.length;
    },
  },
  watch: {
    serviceId(newId) {
      if (newId) {
        this.fetchService(newId);
      }
    },
    isVisible(newVisibility) {
      if (newVisibility) {
        this.selectedCurrency = localStorage.getItem('selectedCurrency') || 'THB';
        this.fetchExchangeRates();
      }
    },
  },
  mounted() {
    this.fetchService(this.serviceId);
    this.fetchExchangeRates();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #e2e8e4;
  color: rgb(0, 108, 132);
  padding: 40px;
  border-radius: 8px;
  width: 90%;
  max-width: 1000px;
  max-height: 80vh;
  overflow-y: auto;
}

.service-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-name {
  font-size: 2rem;
  margin: 0 25px 20px;
  text-align: left;
  width: calc(100% - 50px);
}

.service-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 25px 20px;
  width: calc(100% - 50px);
}

.price-group,
.contact-links-group {
  display: flex;
  align-items: center;
}

.price-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-links p {
  margin: 0;
  margin-right: 10px;
}

.icons {
  display: flex;
  gap: 10px;
}

.icons img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.service-description {
  font-size: 1rem;
  margin: 0 25px;
  text-align: left;
  width: calc(100% - 50px);
}

.service-images {
  position: relative;
  margin: 0 15px;
  width: calc(100% - 30px);
}

.service-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator-dot {
  width: 8px;
  height: 8px;
  background-color: lightgray;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicator-dot.active {
  background-color: rgb(0, 108, 132);
}

/* Мобильная версия */
@media (max-width: 768px) {
  .modal-content {
    padding: 20px;
  }
  .service-name,
  .service-description {
    margin: 0 10px;
    width: calc(100% - 20px);
  }
  .service-actions {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 10px 10px;
    width: calc(100% - 20px);
  }
  .icons img {
    width: 25px;
    height: 25px;
  }
  .service-images {
    margin: 0 5px 20px;
    width: calc(100% - 10px);
  }
}
</style>