<template>
  <div v-if="isOpen" class="modal">
    <div class="modal-content">
      <h1>Создать новый</h1>
      <form @submit.prevent="handleSubmit">
        <!-- Доступность продукта -->
        <div class="form-group">
          <label>В наличии </label>
          <input type="checkbox" v-model="productData.available" />
        </div>

        <!-- Тип продукта -->
        <div class="form-group">
          <label>Тип </label>
          <select v-model="productData.productType" required>
            <option value="товар">Товар</option>
            <option value="услуга">Услуга</option>
          </select>
        </div>

        <!-- Название и описание на русском -->
        <div class="form-group">
          <label>Название </label>
          <input type="text" v-model="productData.nameRu" required />
        </div>
        <div class="form-group">
          <label>Описание </label>
          <textarea v-model="productData.descriptionRu" required></textarea>
        </div>

        <!-- Флажок для английского языка -->
        <div class="form-group">
          <label>English</label>
          <input type="checkbox" v-model="productData.hasEnglish" />
        </div>

        <!-- Название и описание на английском (если выбран английский язык) -->
        <div v-if="productData.hasEnglish">
          <div class="form-group">
            <label>Name </label>
            <input type="text" v-model="productData.nameEn" />
          </div>
          <div class="form-group">
            <label>Description </label>
            <textarea v-model="productData.descriptionEn"></textarea>
          </div>
        </div>

        <!-- Цена -->
        <div class="form-group">
          <label>Цена : </label>
          <input type="number" v-model="productData.priceTHB" required />
          <label> ฿ </label>
        </div>

        <!-- Категории -->
        <div class="form-group">
          <div class="categories">
            <div class="category-column">
              <label><input type="checkbox" v-model="productData.vehicles" /> Транспортные средства</label>
              <label><input type="checkbox" v-model="productData.realestate" /> Недвижимость</label>
              <label><input type="checkbox" v-model="productData.business" /> Бизнес</label>
              <label><input type="checkbox" v-model="productData.tours" /> Экскурсии</label>
            </div>
            <div class="category-column">
              <label><input type="checkbox" v-model="productData.entertainment" /> Развлечения</label>
              <label><input type="checkbox" v-model="productData.music_equipment" /> Музыкальное оборудование</label>
              <label><input type="checkbox" v-model="productData.digital" /> Цифровые товары</label>
              <label><input type="checkbox" v-model="productData.different" /> Разное</label>
            </div>
          </div>
        </div>

        <!-- Фото -->
        <div class="form-group">
          <input type="file" @change="handleFileUpload($event, 0)" />
          <input type="file" v-if="productData.photos.length > 0" @change="handleFileUpload($event, 1)" />
          <input type="file" v-if="productData.photos.length > 1" @change="handleFileUpload($event, 2)" />
        </div>

        <!-- Ссылки для связи -->
        <div v-if="productData.productType === 'услуга'" class="form-group">
          <input type="text" v-model="productData.linkWhatsApp" placeholder="WhatsApp" />
          <input type="text" v-model="productData.linkTelegram" placeholder="Telegram" />
          <input type="text" v-model="productData.linkLine" placeholder="Line" />
          <input type="text" v-model="productData.linkEmail" placeholder="Email" />
          <input type="text" v-model="productData.linkCustom" placeholder="Другая ссылка" />
        </div>

        <!-- Кнопки -->
        <div class="form-group">
          <button type="submit">Создать продукт</button>
          <button type="button" @click="$emit('close')">Закрыть</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      productData: {
        available: true,
        productType: 'товар',
        hasEnglish: false,
        nameRu: '',
        descriptionRu: '',
        nameEn: '',
        descriptionEn: '',
        priceTHB: 0,
        vehicles: false,
        realestate: false,
        business: false,
        tours: false,
        entertainment: false,
        music_equipment: false,
        digital: false,
        different: false,
        photos: [],
        linkWhatsApp: '',
        linkTelegram: '',
        linkLine: '',
        linkEmail: '',
        linkCustom: '',
      },
    };
  },
  methods: {
    handleFileUpload(event, index) {
      const file = event.target.files[0];
      if (file) {
        this.productData.photos[index] = file;
      }
    },
    async handleSubmit() {
      try {
        const formData = new FormData();

        // Логируем перед отправкой
        console.log("Отправляем данные:", this.productData);

        // Добавляем фото
        if (this.productData.photos.length > 0) {
          this.productData.photos.forEach((file) => {
            formData.append('photos', file);
          });
        }

        // Основные данные
        formData.append('available', this.productData.available);
        formData.append('productType', this.productData.productType);
        formData.append('hasEnglish', this.productData.hasEnglish || false);
        formData.append('nameRu', this.productData.nameRu);
        formData.append('descriptionRu', this.productData.descriptionRu);
        if (this.productData.hasEnglish) {
          formData.append('nameEn', this.productData.nameEn);
          formData.append('descriptionEn', this.productData.descriptionEn);
        }
        formData.append('priceTHB', this.productData.priceTHB);

        // Категории
        formData.append('vehicles', this.productData.vehicles);
        formData.append('realestate', this.productData.realestate);
        formData.append('business', this.productData.business);
        formData.append('tours', this.productData.tours);
        formData.append('entertainment', this.productData.entertainment);
        formData.append('music_equipment', this.productData.music_equipment);
        formData.append('digital', this.productData.digital);
        formData.append('different', this.productData.different);

        // Ссылки для связи
        if (this.productData.productType === 'услуга') {
          formData.append('linkWhatsApp', this.productData.linkWhatsApp || '');
          formData.append('linkTelegram', this.productData.linkTelegram || '');
          formData.append('linkLine', this.productData.linkLine || '');
          formData.append('linkEmail', this.productData.linkEmail || '');
          formData.append('linkCustom', this.productData.linkCustom || '');
        }

        // Отправляем запрос
        await this.$axios.post('/api/products', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        alert('Продукт успешно создан!');
        this.$emit('close');
      } catch (error) {
        console.error("Ошибка при создании продукта:", error);
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  width: 650px;
  max-height: 80vh;
  overflow-y: auto;
}

.form-group {
  margin-bottom: 20px;
}

.categories {
    display: flex;
    justify-content: space-between;
  }
  
  .category-column {
    display: flex;
    flex-direction: column;
  }

button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }

button:hover {
  background-color: #45a049;
}
</style>