<template>
  <div class="login-page">
    <h1>Авторизация</h1>
    <form @submit.prevent="login">
      <input type="email" v-model="email" placeholder="Email" required />
      <input type="password" v-model="password" placeholder="Пароль" required />
      <button type="submit">Войти</button>
    </form>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null
    };
  },
  methods: {
    async login() {
      console.log('Попытка входа с данными:', this.email, this.password);
      try {
        const response = await axios.post('/api/users/login', {  
          email: this.email,
          password: this.password
        });

        console.log('Ответ от сервера:', response.data);

        if (response.data.token) {
          console.log('Токен получен, выполняем перенаправление...');
          localStorage.setItem('token', response.data.token);

          this.$router.push('/admin/dashboard')
            .then(() => {
              console.log('Переход на /admin/dashboard выполнен');
            })
            .catch(err => {
              console.error('Ошибка при переходе:', err);
            });
        } else {
          this.error = 'Ошибка при получении токена';
        }
      } catch (error) {
        console.error('Ошибка при попытке входа:', error.response ? error.response.data : error.message);
        this.error = error.response?.data?.message || 'Неверные данные для входа';
      }
    }
  }
};
</script>

<style scoped>
.login-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}
.error {
  color: red;
}
</style>