<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal-content">
      <h3>Редактировать новость</h3>
      <form @submit.prevent="saveNews">
        <div class="form-group">
          <label for="title">Заголовок новости</label>
          <input
            type="text"
            v-model="localArticle.title_ru"
            required
          />
        </div>

        <div class="form-group">
          <label for="content">Содержание новости</label>
          <textarea
            v-model="localArticle.content_ru"
            required
          ></textarea>
        </div>

        <div class="form-group">
          <label for="publishedAt">Дата публикации</label>
          <input
            type="date"
            v-model="localArticle.publishedAt"
            required
          />
        </div>

        <div class="form-actions">
          <button type="submit" class="submit-btn">Сохранить</button>
          <button type="button" class="cancel-btn" @click="closeModal">Закрыть</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    article: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localArticle: {
        title_ru: '',
        content_ru: '',
        publishedAt: '',
      },
    };
  },
  watch: {
    article: {
      immediate: true,
      handler(newArticle) {
        if (newArticle) {
          this.localArticle = { ...newArticle };

          // Преобразуем дату для input[type="date"]
          if (this.localArticle.publishedAt) {
            this.localArticle.publishedAt = new Date(this.localArticle.publishedAt)
              .toISOString()
              .substr(0, 10);
          }
        }
      },
    },
  },
  methods: {
    saveNews() {
      // Отправляем событие с сохраненными данными
      this.$emit('save', { ...this.localArticle });
    },
    closeModal() {
      // Закрытие модального окна
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: white;
}

h3 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #45a049;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #e53935;
}
</style>