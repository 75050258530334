<template>
  <div class="home-page">
    <!-- Меню и корзина -->
    <MenuComponent />
    <CartComponent />

    <!-- Баннер -->
    <div class="banner">
      <img src="@/assets/banner.jpeg" alt="Banner" class="banner-image" />
    </div>

    <!-- О нас -->
    <div class="about-section">
      <h2>{{ $t('aboutUsTitle') }}</h2>
      <p class="about-text"> 
        На Пхукете я с 2018 года, строю жизнь, вдохновленную свободой, уникальными проектами и стремлением делиться опытом. 1-THAI.STORE — это не просто платформа, а результат моей работы и идей, воплощенных с помощью искусственного интеллекта. Весь сайт был создан с нуля исключительно с использованием ИИ, что делает его уникальным отражением моих замыслов и идей.

        Здесь собраны товары и услуги, проверенные лично мной или моими близкими. Это не обычный маркетплейс, а пространство, где я как идейный вдохновитель представляю проекты и предложения для тех, кто хочет познать Пхукет с внутренней стороны.

        Мой путь и мои ценности раскрываются здесь в виде уникальных предложений, услуг и идей, а также через мой YouTube-канал RasТай, в котором я рассказываю о жизни и переменах на Пхукете. Здесь вы найдете всё, чем я занимаюсь, и что могу предложить вам и вашим близким.
      </p>
    </div>

    <!-- Рекомендованные услуги -->
    <div class="recommended-section">
      <h2>{{ $t('recommendedServices') }}</h2>
      <div class="service-list">
        <div
          v-for="service in recommendedServices"
          :key="service.id"
          class="service-item"
          @click="openServiceModal(service)"
        >
          <img :src="getFullImagePath(service.photos[0])" alt="Service Image" class="service-image" />
        </div>
      </div>
    </div>

    <!-- Рекомендованные товары -->
    <div class="recommended-section">
      <h2>{{ $t('recommendedProducts') }}</h2>
      <div class="product-list">
        <div
          v-for="product in recommendedProducts"
          :key="product.id"
          class="product-item"
          @click="openProductModal(product)"
        >
          <img :src="getFullImagePath(product.photos[0])" alt="Product Image" class="product-image" />
        </div>
      </div>
    </div>

    <!-- Последние новости (только для русской версии) -->
    <div v-if="language === 'ru'" class="latest-news">
      <h2>Последние новости</h2>
      <div class="news-list">
        <div
          v-for="news in latestNews"
          :key="news.id"
          class="news-item"
          @click="openNewsModal(news)"
        >
          <h3>{{ news.title_ru }}</h3>
          <p>{{ news.content_ru }}</p>
        </div>
      </div>
    </div>

    <!-- Модальные окна -->
    <ServiceModal
      v-if="selectedService"
      :serviceId="String(selectedService.id)"
      :isVisible="isServiceModalVisible"
      @close="closeServiceModal"
    />
    <ProductModal
      v-if="selectedProduct"
      :productId="String(selectedProduct.id)"
      :isVisible="isProductModalVisible"
      @close="closeProductModal"
    />
    <NewsDetailModal
      v-if="selectedNews"
      :isVisible="isNewsModalVisible"
      :news="selectedNews"
      @close="closeNewsModal"
    />

    <!-- Футер -->
    <footer class="footer">
      <p>{{ $t('footerRights') }}</p>
    </footer>
  </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import CartComponent from '@/components/CartComponent.vue';
import ServiceModal from '@/components/ServiceModal.vue';
import ProductModal from '@/components/ProductModal.vue';
import NewsDetailModal from '@/components/NewsDetailModal.vue';
import axios from 'axios';

export default {
  components: {
    MenuComponent,
    CartComponent,
    ServiceModal,
    ProductModal,
    NewsDetailModal,
  },
  data() {
    return {
      recommendedServices: [],
      recommendedProducts: [],
      latestNews: [],
      selectedService: null,
      selectedProduct: null,
      selectedNews: null,
      isServiceModalVisible: false,
      isProductModalVisible: false,
      isNewsModalVisible: false,
      language: localStorage.getItem('selectedLanguage') || 'ru',
    };
  },
  async mounted() {
    await this.fetchRecommendedServices();
    await this.fetchRecommendedProducts();
    await this.fetchLatestNews();
  },
  methods: {
    async fetchRecommendedServices() {
      const response = await axios.get('/api/products');
      this.recommendedServices = response.data
        .filter(product => product.productType === 'услуга' && (product.hasEnglish || this.language === 'ru'))
        .slice(0, 4);
    },
    async fetchRecommendedProducts() {
      const response = await axios.get('/api/products');
      this.recommendedProducts = response.data
        .filter(product => product.productType === 'товар' && (product.hasEnglish || this.language === 'ru'))
        .slice(0, 4);
    },
    async fetchLatestNews() {
      const response = await axios.get('/api/news');
      this.latestNews = response.data.slice(0, 4);
    },
    openServiceModal(service) {
      this.selectedService = service;
      this.isServiceModalVisible = true;
    },
    closeServiceModal() {
      this.selectedService = null;
      this.isServiceModalVisible = false;
    },
    openProductModal(product) {
      this.selectedProduct = product;
      this.isProductModalVisible = true;
    },
    closeProductModal() {
      this.selectedProduct = null;
      this.isProductModalVisible = false;
    },
    openNewsModal(news) {
      this.selectedNews = news;
      this.isNewsModalVisible = true;
    },
    closeNewsModal() {
      this.selectedNews = null;
      this.isNewsModalVisible = false;
    },
    getFullImagePath(imagePath) {
      return imagePath ? `${window.location.origin}${imagePath}` : 'default-image.jpg';
    }
  },
};
</script>

<style scoped>
.home-page {
  position: relative;
  min-height: 100vh;
  background-image: url('@/assets/overlay.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  color: white;
}

.banner {
  width: 100%;
  height: 220px;
  margin-top: 100px;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.about-section {
  width: 80%;
  max-width: 1200px;
  margin: 40px auto;
  text-align: center;
}

.about-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.6;
  padding: 0 20px;
}

.recommended-section,
.latest-news {
  width: 80%;
  max-width: 1200px;
  text-align: center;
  margin: 30px auto;
}

.service-list,
.product-list,
.news-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.service-item,
.product-item {
  cursor: pointer;
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 8px;
}

.service-image,
.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  height: 250px;
}

.news-item h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
  white-space: normal;
}

.news-item p {
  font-size: 14px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.footer {
  background-color: #6eb5c0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9990;
}

/* Мобильные стили для 2 колонок */
@media (max-width: 768px) {
  .service-list,
  .product-list,
  .news-list {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .news-item p {
    display: none;
  }
}
</style>