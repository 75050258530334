import { createI18n } from 'vue-i18n';

// Определим переводы
const messages = {
  en: {
    home: 'Home',
    catalog: 'Catalog',
    news: 'Blog',
    contacts: 'Contacts',
    contactsTitle: 'Our Contacts',
    welcomeMessage: 'Welcome!',
    bestProducts: 'The best products at the best prices!',
    changeLanguage: 'Change Language',
    allProducts: 'All',
    products: 'Products',
    services: 'Services',
    allCategories: 'All Categories',
    vehicles: 'Vehicles',
    realEstate: 'Real Estate',
    business: 'Business',
    tours: 'Tours',
    entertainment: 'Entertainment',
    musicEquipment: 'Music Equipment',
    digitalGoods: 'Digital Goods',
    miscellaneous: 'Miscellaneous',
    searchPlaceholder: 'Search...',
    price: 'Price',
    noAvailableProducts: 'No available products for the selected filter.',
    category: 'Category',
    sortBy: 'Sort by',
    random: 'Random',
    priceAsc: 'Price ↑',
    priceDesc: 'Price ↓',
    footerRights: '© 2024 "1-THAI.STORE". All rights reserved.',
    cartTitle: 'Shopping Cart',
    cartSummary: 'Your cart with items totaling {total} {currency}', // Заголовок с суммой
    productName: 'Product Name',
    quantity: 'Quantity',
    total: 'Total',
    clearCart: 'Clear Cart',
    checkout: 'Checkout',
    from: 'from',
    emptyCart: 'Your cart is empty.',
    remove: 'Remove', // Кнопка удаления
    noNews: 'No news available.',
    contactManager: 'Write in ',
    checkoutTitle: 'Checkout',
    customerName: 'Name',
    contactPhone: 'Contact Phone',
    preferredMessenger: 'Preferred Messenger',
    orderComment: 'Order Comment',
    commentPlaceholder: 'You can provide your delivery address or any other important order details here.',
    submitOrder: 'Submit Order',
    cancelOrder: 'Cancel',
    orderConfirmed: 'Order Confirmed',
    unit_single: 'pc',
    unit_plural: 'pcs',

    // Недостающие для HomePage
    welcomeSlogan: 'Welcome to 1-thai.store',
    aboutUsTitle: 'About Us',
    aboutUsDescription: 'Your trusted store for the best products and services on Phuket!',
    recommendedServices: 'Recommended Services',
    recommendedProducts: 'Recommended Products',
    contactUs: 'Contact Us',
    contactLocation: 'We are located in Phuket. Visit our contacts page for more details.',
    goToContacts: 'Go to Contacts',
  },
  ru: {
    home: 'Главная',
    catalog: 'Каталог',
    news: 'Блог',
    contacts: 'Контакты',
    contactsTitle: 'Контакты',
    welcomeMessage: 'Добро пожаловать!',
    bestProducts: 'Лучшие товары по лучшим ценам!',
    changeLanguage: 'Сменить язык',
    allProducts: 'Все',
    products: 'Товары',
    services: 'Услуги',
    allCategories: 'Все категории',
    vehicles: 'Транспортные средства',
    realEstate: 'Недвижимость',
    business: 'Бизнес',
    tours: 'Экскурсии',
    entertainment: 'Развлечения',
    musicEquipment: 'Музыкальное оборудование',
    digitalGoods: 'Цифровые товары',
    miscellaneous: 'Разное',
    searchPlaceholder: 'Поиск по названию или описанию',
    price: 'Цена',
    noAvailableProducts: 'Нет доступных продуктов для выбранного фильтра.',
    category: 'Категория',
    sortBy: 'Сортировка',
    random: 'Случайно',
    priceAsc: 'Цена ↑',
    priceDesc: 'Цена ↓',
    footerRights: '© 2024 "1-THAI.STORE". Все права защищены.',
    cartTitle: 'Ваша корзина',
    cartSummary: 'В вашей корзине товаров на сумму {total} {currency}', // Заголовок с суммой
    productName: 'Название товара',
    quantity: 'Количество',
    total: 'Итого',
    clearCart: 'Очистить корзину',
    checkout: 'Оформить заказ',
    from: 'от',
    emptyCart: 'Корзина пуста.',
    remove: 'Удалить', // Кнопка удаления
    noNews: 'Новостей нет.',
    contactManager: 'Написать в ',
    checkoutTitle: 'Оформление заказа',
    customerName: 'Имя',
    contactPhone: 'Контактный телефон',
    preferredMessenger: 'Предпочтительный мессенджер',
    orderComment: 'Комментарий к заказу',
    commentPlaceholder: 'Здесь вы можете указать ваш адрес доставки или любую другую важную информацию для заказа.',
    submitOrder: 'Оформить заказ',
    cancelOrder: 'Отмена',
    orderConfirmed: 'Заказ подтвержден',
    unit_single: 'шт.',
    unit_plural: 'шт.',

    // Недостающие для HomePage
    welcomeSlogan: 'Добро пожаловать в интернет-магазин 1-thai.store',
    aboutUsTitle: 'О нас',
    aboutUsDescription: 'Ваш надежный магазин для лучших товаров и услуг на Пхукете!',
    recommendedServices: 'Рекомендованные услуги',
    recommendedProducts: 'Рекомендованные товары',
    contactUs: 'Контакты',
    contactLocation: 'Мы находимся на Пхукете. Перейдите на страницу контактов для подробной информации.',
    goToContacts: 'Перейти к контактам',
  },
};

// Инициализация i18n
const i18n = createI18n({
  locale: localStorage.getItem('selectedLanguage') || 'ru',
  fallbackLocale: 'en',
  messages,
});

export default i18n;