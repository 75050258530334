<template>
  <div class="filter-currency">
    <!-- Кнопки для выбора валюты -->
    <button :class="{ active: selectedCurrency === 'THB' }" @click="setCurrency('THB')">THB</button>
    <button :class="{ active: selectedCurrency === 'RUB' }" @click="setCurrency('RUB')">RUB</button>
    <button :class="{ active: selectedCurrency === 'USDT' }" @click="setCurrency('USDT')">USDT</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCurrency: localStorage.getItem('selectedCurrency') || 'THB', // Получаем валюту из LocalStorage
      exchangeRates: { RUB: 1, USDT: 1 }, // Курсы обмена валют, загружаемые с бэкенда
      selectedCurrencySymbol: this.getCurrencySymbol(localStorage.getItem('selectedCurrency') || 'THB'), // Символ выбранной валюты
    };
  },
  methods: {
    // Метод для установки выбранной валюты
    setCurrency(currency) {
      this.selectedCurrency = currency;

      // Устанавливаем символ валюты
      this.selectedCurrencySymbol = this.getCurrencySymbol(currency);

      // Сохраняем валюту в LocalStorage
      localStorage.setItem('selectedCurrency', this.selectedCurrency);

      // Опционально, если нужна реакция других компонентов, можно вызывать $emit
      this.$emit('currencyChanged', this.selectedCurrency, this.selectedCurrencySymbol);
    },

    // Метод для получения символа валюты
    getCurrencySymbol(currency) {
      switch (currency) {
        case 'RUB':
          return '₽';
        case 'USDT':
          return 'USDT';
        default:
          return '฿';
      }
    },

    // Метод для получения текущих курсов валют с бэкенда
    async fetchExchangeRates() {
      try {
        const response = await this.$axios.get('/api/currency'); // Эндпоинт для получения курсов валют

        // Проверяем, что объект rates существует в ответе
        if (response.data && response.data.rates) {
          this.exchangeRates.RUB = response.data.rates.RUB || 1;
          this.exchangeRates.USDT = response.data.rates.USDT || 1;
        } else {
          console.error('Курсы валют не найдены в ответе API');
        }
      } catch (error) {
        console.error('Ошибка при загрузке курсов валют:', error);
      }
    },

    // Метод для загрузки сохраненной валюты из LocalStorage
    loadCurrencyFromLocalStorage() {
      const savedCurrency = localStorage.getItem('selectedCurrency');
      if (savedCurrency) {
        this.setCurrency(savedCurrency); // Устанавливаем сохраненную валюту
      }
    },
  },

  mounted() {
    this.fetchExchangeRates(); // Загружаем курсы валют при инициализации компонента
    this.loadCurrencyFromLocalStorage(); // Загружаем сохраненную валюту
  },
};
</script>

<style scoped>
/* Стили для кнопок валют */
.filter-currency {
  display: flex;
  justify-content: center;
}

.filter-currency button {
  padding: 10px 20px;
  background-color: rgb(255, 255, 255);
  color: #006c84;
  border: none;
  cursor: pointer;
}

.filter-currency button.active {
  background-color: #5cafbc;
  color: rgb(255, 255, 255);
}
</style>