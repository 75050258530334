<template>
  <div class="cart-page">
    <!-- Обновленный заголовок с общей суммой -->
    <h1 class="cart-title">
      {{ $t('cartSummary', { total: roundedTotalPrice, currency: selectedCurrencySymbol }) }}
    </h1>
    <MenuComponent />

    <div class="content">
      <!-- Очистить корзину и выбор валюты на одном уровне -->
      <div class="header-row">
        <p class="clear-cart-text" @click="clearCart">{{ $t('clearCart') }}</p>
        <div class="currency-converter-container">
          <CurrencyConverter @currencyChanged="handleCurrencyChange" />
        </div>
      </div>

      <!-- Список товаров в корзине -->
      <div v-if="cartItems && cartItems.length > 0" class="cart-items">
        <div v-for="(item, index) in cartItems" :key="item.id" class="cart-item">
          <!-- Первая строка: номер и название -->
          <div class="item-header">
            <p class="item-index">{{ index + 1 }}.</p>
            <p class="item-name">{{ getProductName(item.product) || 'Unknown Product' }}</p>
          </div>

          <!-- Вторая строка: количество, общая стоимость и кнопка удаления -->
          <div class="item-details">
            <div class="left-details">
              <p class="quantity-text">{{ item.quantity }} {{ translatedUnit(item.quantity) }}</p>
              <p class="item-total-price">{{ item.quantity * productPrice(item.product) || 0 }} {{ selectedCurrencySymbol }}</p>
            </div>
            <button class="delete-btn" @click="removeFromCart(item.productId)">{{ $t('remove') }}</button>
          </div>
        </div>

        <!-- Кнопка оформления заказа по центру -->
        <div class="checkout-section">
          <button class="checkout-button" @click="proceedToCheckout">{{ $t('checkout') }}</button>
        </div>
      </div>

      <!-- Сообщение о пустой корзине -->
      <div class="empty-cart-message" v-else>
        <p>{{ $t('emptyCart') }}</p>
      </div>

      <CheckoutModal 
        :isVisible="isCheckoutModalVisible" 
        :cartItems="cartItems"
        :totalPriceTHB="totalPriceInTHB" 
        :totalPriceOtherCurrency="totalPriceInCurrency" 
        :currency="selectedCurrency"
        @close="closeCheckoutModal" 
        @orderPlaced="handleOrderPlaced"  
      />
    </div>

    <footer class="footer">
      <p>{{ $t('footerRights') }}</p>
    </footer>
  </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import CurrencyConverter from '@/components/CurrencyConverter.vue';
import CheckoutModal from '@/components/CheckoutModal.vue';
import eventBus from '@/eventBus';

export default {
  components: {
    MenuComponent,
    CurrencyConverter,
    CheckoutModal,
  },
  data() {
    return {
      cartItems: [],
      selectedCurrency: localStorage.getItem('selectedCurrency') || 'THB',
      selectedCurrencySymbol: '฿',
      exchangeRates: {},
      isCheckoutModalVisible: false,
      totalPriceInTHB: 0,
      totalPriceInCurrency: 0,
    };
  },
  computed: {
    calculateTotalPriceTHB() {
      return this.cartItems.reduce((total, item) => {
        return total + (item.product?.priceTHB || 0) * item.quantity;
      }, 0);
    },
    calculateTotalPriceInCurrency() {
      return this.cartItems.reduce((total, item) => {
        const priceInCurrency = (item.product?.priceTHB || 0) * (this.exchangeRates[this.selectedCurrency] || 1);
        return total + priceInCurrency * item.quantity;
      }, 0);
    },
    roundedTotalPrice() {
      return Math.round(this.calculateTotalPriceInCurrency);
    },
    translatedUnit() {
      return (quantity) => {
        return quantity === 1 ? this.$t('unit_single') : this.$t('unit_plural');
      };
    },
  },
  mounted() {
    this.fetchCart();
    this.fetchExchangeRates();
    eventBus.on('currencyChanged', this.handleCurrencyChange);
  },
  beforeUnmount() {
    eventBus.off('currencyChanged', this.handleCurrencyChange);
  },
  methods: {
    async handleCurrencyChange(newCurrency, symbol) {
      this.selectedCurrency = newCurrency;
      this.selectedCurrencySymbol = newCurrency === 'USDT' ? '₮' : symbol;
      await this.fetchExchangeRates();
      this.updatePricesInCart();
    },
    updatePricesInCart() {
      this.cartItems = this.cartItems.map(item => ({
        ...item,
        priceInCurrency: this.productPrice(item.product),
      }));
      this.totalPriceInTHB = this.calculateTotalPriceTHB;
      this.totalPriceInCurrency = this.calculateTotalPriceInCurrency;
    },
    productPrice(product) {
      if (!product || !this.exchangeRates[this.selectedCurrency]) return 0;
      return Math.round((product.priceTHB || 0) * this.exchangeRates[this.selectedCurrency]);
    },
    getProductName(product) {
      const language = localStorage.getItem('selectedLanguage') || 'ru';
      return product ? (language === 'ru' ? product.nameRu : product.nameEn) : 'Unknown Product';
    },
    async removeFromCart(productId) {
      try {
        const sessionId = localStorage.getItem('sessionId') || 'default-session';
        const response = await this.$axios.delete(`/api/cart/remove`, {
          data: { sessionId, productId },
        });
        if (response.status === 200) {
          this.fetchCart();
        }
      } catch (error) {
        console.error('Ошибка при удалении товара из корзины:', error);
      }
    },
    async clearCart() {
      await this.$axios.delete('/api/cart/clear', {
        data: { sessionId: localStorage.getItem('sessionId') || 'default-session' },
      });
      this.cartItems = [];
    },
    proceedToCheckout() {
      this.isCheckoutModalVisible = true;
    },
    closeCheckoutModal() {
      this.isCheckoutModalVisible = false;
    },
    handleOrderPlaced() {
      this.fetchCart();
    },
    async fetchCart() {
      const sessionId = localStorage.getItem('sessionId') || 'default-session';
      const response = await this.$axios.get(`/api/cart`, { params: { sessionId } });
      if (response.status === 200 && response.data.items) {
        this.cartItems = response.data.items;
        this.updatePricesInCart();
      } else {
        this.cartItems = [];
      }
    },
    async fetchExchangeRates() {
      const response = await this.$axios.get('/api/currency');
      this.exchangeRates = response.data.rates;
    },
  },
};
</script>

<style scoped>
.cart-page {
  position: relative;
  min-height: 100vh;
  background-image: url('@/assets/overlay.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(0, 108, 132);
}

.cart-title {
  color: #ffffff;
  text-align: center;
  margin-top: 60px;
}

.content {
  flex: 1;
  width: 70%;
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.currency-converter-container {
  margin-right: 10px;
}

.cart-items {
  margin-top: 20px;
}

.cart-item {
  border: 1px solid #006c84;
  background-color: rgba(226, 232, 228, 0.7);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.item-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.left-details {
  display: flex;
  gap: 20px;
}

.quantity-text,
.item-total-price {
  font-size: 16px;
}

.delete-btn {
  padding: 5px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #e74c3c;
  border-radius: 3px;
}

.delete-btn:hover {
  color: #c0392b;
}

.checkout-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.checkout-button {
  background-color: #6eb5c0;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.checkout-button:hover {
  background-color: #55a3b2;
}

.footer {
  background-color: #6eb5c0;
  color: #ffffff;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
</style>