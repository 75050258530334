<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <h2>{{ $t('checkoutTitle') }}</h2>
      <form @submit.prevent="submitOrder">
        <!-- Имя -->
        <div class="form-group">
          <label for="name">{{ $t('customerName') }}</label>
          <input type="text" id="name" v-model="orderData.name" required />
        </div>

        <!-- Контактный телефон -->
        <div class="form-group">
          <label for="phone">{{ $t('contactPhone') }}</label>
          <input type="tel" id="phone" v-model="orderData.phone" required />
        </div>

        <!-- Предпочтительные мессенджеры -->
        <div class="form-group">
          <label>{{ $t('preferredMessenger') }}</label>
          <div class="messenger-options">
            <div class="messenger-option" @click="toggleMessenger('whatsapp')">
              <img src="@/assets/whatsapp.png" :class="{ active: orderData.messengers.includes('whatsapp') }" alt="WhatsApp" />
            </div>
            <div class="messenger-option" @click="toggleMessenger('telegram')">
              <img src="@/assets/telegram.png" :class="{ active: orderData.messengers.includes('telegram') }" alt="Telegram" />
            </div>
            <div class="messenger-option" @click="toggleMessenger('line')">
              <img src="@/assets/line.png" :class="{ active: orderData.messengers.includes('line') }" alt="Line" />
            </div>
          </div>
        </div>

        <!-- Комментарий к заказу -->
        <div class="form-group">
          <label for="comment">{{ $t('orderComment') }}</label>
          <textarea id="comment" v-model="orderData.comment" :placeholder="$t('commentPlaceholder')"></textarea>
        </div>

        <!-- Кнопки действия -->
        <div class="form-actions">
          <button type="button" class="cancel-btn" @click="closeModal">{{ $t('cancelOrder') }}</button>
          <button type="submit" class="submit-btn">{{ $t('submitOrder') }}</button>
        </div>
      </form>
    </div>

    <!-- Pop-up с подтверждением заказа -->
    <div v-if="showConfirmation" class="confirmation-popup">
      <p>{{ $t('orderConfirmed') }}</p>
      <button @click="closeConfirmation">OK</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CheckoutModal',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cartItems: {
      type: Array,
      default: () => [],
    },
    totalPriceTHB: {
      type: Number,
      default: 0,
    },
    totalPriceOtherCurrency: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: 'THB',
    },
  },
  data() {
    return {
      orderData: {
        name: '',
        phone: '',
        messengers: [],
        comment: '',
      },
      showConfirmation: false,
      language: localStorage.getItem('selectedLanguage') || 'ru',
    };
  },
  watch: {
    isVisible(newVal) {
      document.body.style.overflow = newVal ? 'hidden' : 'auto';
    },
  },
  methods: {
    toggleMessenger(messenger) {
      const index = this.orderData.messengers.indexOf(messenger);
      if (index === -1) {
        this.orderData.messengers.push(messenger);
      } else {
        this.orderData.messengers.splice(index, 1);
      }
    },

    async submitOrder() {
      try {
        const sessionId = localStorage.getItem('sessionId') || 'default-session';
        const order = {
          name: this.orderData.name,
          contactInfo: {
            phone: this.orderData.phone,
            messengers: this.orderData.messengers.length > 0 ? this.orderData.messengers : null,
          },
          comment: this.orderData.comment,
          sessionId,
          language: this.language,
          currency: this.currency,
          totalPriceTHB: this.totalPriceTHB,
          totalPriceOtherCurrency: this.totalPriceOtherCurrency,
          items: this.cartItems.map(item => ({
            productId: item.product.id,
            productName: item.product.nameRu || item.product.nameEn,
            quantity: item.quantity,
            priceTHB: item.product.priceTHB,
            priceOtherCurrency: item.priceInCurrency || null,
          })),
        };

        await axios.post('/api/orders/create', order, {
          headers: { 'Content-Type': 'application/json' },
        });

        this.showConfirmation = true;
        this.clearForm();
        this.$emit('orderPlaced');
      } catch (error) {
        console.error('Ошибка при оформлении заказа:', error.response ? error.response.data : error.message);
      }
    },

    closeModal() {
      this.$emit('close');
    },

    closeConfirmation() {
      this.showConfirmation = false;
      this.closeModal();
      this.$emit('orderPlaced');
      this.$router.push('/');
    },

    clearForm() {
      this.orderData = {
        name: '',
        phone: '',
        messengers: [],
        comment: '',
      };
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.modal-content {
  background-color: rgb(226, 232, 228);
  color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90vw;
  max-height: calc(100vh - 90px); /* Отступ 50px сверху и снизу */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin: 50px 0; /* Отступ сверху и снизу */
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.messenger-options {
  display: flex;
  justify-content: space-around;
}

.messenger-option img {
  width: 50px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.messenger-option img.active {
  opacity: 1;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-btn {
  background-color: rgb(110, 181, 192);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(0, 108, 132);
}

.cancel-btn {
  background-color: #929292;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: rgb(64, 64, 64);
}

.confirmation-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirmation-popup p {
  margin-bottom: 20px;
  font-size: 18px;
}

.confirmation-popup button {
  background-color: #728498;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-popup button:hover {
  background-color: #0056b3;
}
</style>