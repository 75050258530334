<template>
  <div class="catalog-page">
    <h1>{{ $t('catalog') }}</h1>
    <MenuComponent />

    <div class="filter-bar">
      <input
        type="text"
        v-model="searchQuery"
        :placeholder="$t('searchPlaceholder')"
        class="filter-search"
      />
      <div class="filter-buttons">
        <button @click="toggleFilters" class="sort-button">
          <img src="@/assets/sort.png" alt="Sort" class="sort-icon" />
        </button>
        <CurrencyConverter @currencyChanged="handleCurrencyChange" />
      </div>
    </div>

    <div v-if="areFiltersVisible" class="filters-container">
      <div class="filter-sort">
        <select v-model="sortOption" @change="applySortFilter">
          <option value="random">{{ $t('random') }}</option>
          <option value="nameAsc">ABC ↓</option>
          <option value="nameDesc">↑ ABC</option>
          <option value="priceAsc">{{ $t('priceAsc') }}</option>
          <option value="priceDesc">{{ $t('priceDesc') }}</option>
        </select>
      </div>

      <div class="filter-category">
        <select v-model="productType" @change="applyProductTypeFilter">
          <option value="all">{{ $t('allProducts') }}</option>
          <option value="товар">{{ $t('products') }}</option>
          <option value="услуга">{{ $t('services') }}</option>
        </select>
      </div>

      <div class="filter-category">
        <select v-model="selectedCategory" @change="applyCategoryFilter">
          <option value="">{{ $t('allCategories') }}</option>
          <option
            v-for="category in categories"
            :key="category.value"
            :value="category.value"
          >
            {{ $t(category.label) }}
          </option>
        </select>
      </div>
    </div>

    <div class="content">
      <div v-if="filteredProducts.length > 0" class="product-list">
        <div
          v-for="product in filteredProducts"
          :key="product.id"
          class="product-item"
          @click="openModal(product)"
        >
          <div class="product-image-container">
            <img
              :src="getFullImagePath(product.photos && product.photos[0])"
              alt="Product Image"
              class="product-image"
            />
          </div>
          <div class="product-item-content">
            <h2>{{ productName(product) }}</h2>
            <p v-if="product.productType === 'услуга'">
              {{ $t('from') }} {{ productPrice(product) }}
              {{ selectedCurrencySymbol }}
            </p>
            <p v-else>
              {{ productPrice(product) }} {{ selectedCurrencySymbol }}
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{ $t('noAvailableProducts') }}</p>
      </div>
    </div>

    <ProductModal
      v-if="selectedProduct && selectedProduct.productType === 'товар'"
      :productId="String(selectedProduct.id)"
      :isVisible="isModalVisible"
      @close="closeModal"
      @cartUpdated="updateCartTotal"
    />
    <ServiceModal
      v-if="selectedProduct && selectedProduct.productType === 'услуга'"
      :serviceId="String(selectedProduct.id)"
      :isVisible="isModalVisible"
      @close="closeModal"
    />

    <div
      v-if="cartTotal > 0 && !isModalVisible"
      class="fixed-cart-button"
      @click="goToCart"
    >
      <img class="cart-image" src="@/assets/cart.png" alt="Корзина" />
      <span class="cart-total">{{ convertedCartTotal }} {{ selectedCurrencySymbol }}</span>
    </div>

    <button v-show="showScrollButton" class="scroll-to-top" @click="scrollToTop">
      <img src="@/assets/uparrow.png" alt="Scroll to top" class="uparrow-icon" />
    </button>

    <footer class="footer">
      <p>{{ $t('footerRights') }}</p>
    </footer>
  </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import CurrencyConverter from '@/components/CurrencyConverter.vue';
import ProductModal from '@/components/ProductModal.vue';
import ServiceModal from '@/components/ServiceModal.vue';
import axios from 'axios';

export default {
  components: {
    MenuComponent,
    CurrencyConverter,
    ProductModal,
    ServiceModal,
  },
  data() {
    return {
      products: [],
      language: '',
      productType: 'all',
      selectedCategory: '',
      searchQuery: '',
      sortOption: 'random',
      selectedCurrency: 'THB',
      exchangeRates: { RUB: 1, USDT: 1 },
      selectedCurrencySymbol: '฿',
      selectedProduct: null,
      isModalVisible: false,
      areFiltersVisible: false,
      showScrollButton: false,
      cart: [],
      cartTotal: 0,
      convertedCartTotal: 0,
      categories: [
        { value: 'vehicles', label: 'vehicles' },
        { value: 'realestate', label: 'realEstate' },
        { value: 'business', label: 'business' },
        { value: 'tours', label: 'tours' },
        { value: 'entertainment', label: 'entertainment' },
        { value: 'music_equipment', label: 'musicEquipment' },
        { value: 'digital', label: 'digitalGoods' },
        { value: 'different', label: 'miscellaneous' },
      ],
    };
  },
  computed: {
    filteredProducts() {
      let sortedProducts = [...this.products];
      return sortedProducts.filter((product) => {
        if (this.language === 'en' && !product.hasEnglish) {
          return false;
        }
        if (this.selectedCategory && !product[this.selectedCategory]) {
          return false;
        }
        if (this.productType !== 'all' && product.productType !== this.productType) {
          return false;
        }
        const query = this.searchQuery.toLowerCase();
        const name = this.productName(product).toLowerCase();
        const description = this.productDescription(product).toLowerCase();
        if (query && !(name.includes(query) || description.includes(query))) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    getFullImagePath(imagePath) {
      return imagePath ? `${window.location.origin}${imagePath}` : 'default-image.jpg';
    },
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
    },
    applyProductTypeFilter() {},
    applyCategoryFilter() {},
    applySortFilter() {},
    openModal(product) {
      this.selectedProduct = product;
      this.isModalVisible = true;
    },
    closeModal() {
      this.selectedProduct = null;
      this.isModalVisible = false;
    },
    productName(product) {
      return this.language === 'ru' ? product.nameRu : product.nameEn;
    },
    productDescription(product) {
      return this.language === 'ru' ? product.descriptionRu : product.descriptionEn;
    },
    productPrice(product) {
      const price = product.priceTHB * this.exchangeRates[this.selectedCurrency];
      return Math.round(price);
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/api/products');
        this.products = response.data;
      } catch (error) {
        console.error('Ошибка при загрузке продуктов:', error);
      }
    },
    handleCurrencyChange(currency) {
      this.selectedCurrency = currency;
      this.selectedCurrencySymbol = this.getCurrencySymbol(currency);
      this.convertCartTotal();
    },
    getCurrencySymbol(currency) {
      switch (currency) {
        case 'RUB':
          return '₽';
        case 'USDT':
          return '₮';
        default:
          return '฿';
      }
    },
    async fetchExchangeRates() {
      try {
        const response = await axios.get('/api/currency');
        this.exchangeRates = response.data.rates || {};
      } catch (error) {
        console.error('Ошибка при загрузке курсов валют:', error);
      }
    },
    getLanguage() {
      this.language = localStorage.getItem('selectedLanguage') || 'ru';
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showScrollButton = window.scrollY > 100;
    },
    async fetchCart() {
      try {
        const response = await axios.get('/api/cart', {
          params: { sessionId: localStorage.getItem('sessionId') || 'default-session' },
        });
        this.cart = response.data.items || [];
        this.updateCartTotal();
      } catch (error) {
        console.error('Ошибка при загрузке корзины:', error);
      }
    },
    updateCartTotal() {
      this.cartTotal = this.cart.reduce((total, item) => {
        return total + (item.product?.priceTHB || 0) * item.quantity;
      }, 0);
      this.convertCartTotal();
    },
    convertCartTotal() {
      this.convertedCartTotal = Math.round(this.cartTotal * (this.exchangeRates[this.selectedCurrency] || 1));
    },
    goToCart() {
      this.$router.push('/cart');
    },
  },
  mounted() {
    this.getLanguage();
    this.fetchProducts();
    this.fetchExchangeRates();
    this.fetchCart();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.catalog-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url('@/assets/overlay.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

h1 {
  color: #ffffff;
  text-align: center;
  margin-top: 60px;
}

.content {
  flex: 1;
  margin-bottom: 40px;
}

.footer {
  background-color: #6eb5c0;
  color: #ffffff;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  z-index: 99000;
}

.scroll-to-top {
  position: fixed;
  bottom: 80px;
  right: 40px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 99;
}

.scroll-to-top .uparrow-icon {
  width: 40px;
  height: 40px;
}

.scroll-to-top:hover .uparrow-icon {
  transform: scale(1.1);
  transition: transform 0.2s;
}

.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 50px;
  gap: 20px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .filter-bar {
    flex-wrap: wrap;
  }
  .filter-search {
    flex: 1 1 100%;
    margin-bottom: 10px;
  }
  .filter-buttons {
    flex: 1 1 100%;
    justify-content: center;
  }
}

.filter-search {
  width: 100%;
  padding: 10px;
  border-radius: 2px;
  background-color: #ffffff;
}

.sort-button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-right: 30px;
}

.sort-icon {
  height: 30px;
  width: 30px;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-evenly;
  padding: 10px 50px;
}

.product-type-filter button,
.filter-category select,
.filter-sort select {
  padding: 10px;
  border-radius: 0;
  border: none;
  background-color: #ffffff;
  color: #006c84;
}

.product-type-filter button.active {
  background-color: #6eb5c0;
  color: #ffffff;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 100%;
  width: 100%;
}

.product-item {
  background-color: rgba(226, 232, 228, 0);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
}

.product-item:hover .product-image {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.product-image-container {
  width: 100%;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-item-content h2,
.product-item-content p {
  color: #ffffff;
  font-weight: bold;
}

.fixed-cart-button {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(110, 181, 192, 0);
  color: white;
  padding: 3px 8px;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 4px;
}

.fixed-cart-button:hover {
  background-color: rgba(0, 108, 132, 0.8);
}

.cart-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.cart-total {
  font-size: 18px;
  font-weight: bold;
}
</style>