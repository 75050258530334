<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <div class="product-modal">
        <!-- Название продукта -->
        <h1 class="product-name">{{ productName }}</h1>

        <!-- Карусель изображений -->
        <div class="product-images" @click="handleImageClick">
          <div v-if="product.photos && product.photos.length > 0" class="carousel">
            <img
              :src="getFullImagePath(product.photos[currentImageIndex])"
              alt="Product Image"
              class="product-image"
            />
            <!-- Точки для отображения текущей фотографии -->
            <div class="carousel-indicators">
              <span
                v-for="(photo, index) in product.photos"
                :key="index"
                :class="{'active': currentImageIndex === index}"
                class="indicator-dot"
              ></span>
            </div>
          </div>
        </div>

        <!-- Строка с ценой и кнопкой добавления в корзину -->
        <div class="product-actions">
          <p class="product-price">{{ productPrice }} {{ selectedCurrencySymbol }}</p>
          <div class="cart-actions">
            <button v-if="isInCart" class="quantity-btn" @click="decreaseQuantity">-</button>
            <span v-if="isInCart" class="quantity-value">{{ quantityInCart }}</span>
            <button v-if="isInCart" class="quantity-btn" @click="increaseQuantity">+</button>
            <button v-else class="cart-btn" @click="addToCart">Добавить в корзину</button>
          </div>
        </div>

        <!-- Описание продукта с рендерингом HTML для новых строк -->
        <p class="product-description" v-html="productDescription"></p>
      </div>

      <!-- Фиксированная кнопка корзины в правом верхнем углу -->
      <div v-if="cartTotal > 0" class="fixed-cart-button" @click="goToCart">
        <img class="cart-image" src="@/assets/cart.png" alt="Корзина" />
        <span class="cart-total">{{ convertedCartTotal }} {{ selectedCurrencySymbol }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProductModal',
  props: {
    productId: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: {},
      selectedCurrency: localStorage.getItem('selectedCurrency') || 'THB',
      selectedCurrencySymbol: '฿',
      currentImageIndex: 0,
      exchangeRates: { RUB: 1, USDT: 1 },
      cart: [],
      cartTotal: 0,
      convertedCartTotal: 0,
    };
  },
  computed: {
    productName() {
      const language = localStorage.getItem('selectedLanguage') || 'ru';
      return language === 'ru' ? this.product.nameRu : this.product.nameEn;
    },
    productDescription() {
      const language = localStorage.getItem('selectedLanguage') || 'ru';
      const description = language === 'ru' ? this.product.descriptionRu : this.product.descriptionEn;
      return description ? description.replace(/\n/g, '<br>') : '';
    },
    productPrice() {
      const price = this.product?.priceTHB * (this.exchangeRates[this.selectedCurrency] || 1);
      return Math.round(price);
    },
    isInCart() {
      return this.cart.some((item) => item.productId === this.product.id);
    },
    quantityInCart() {
      const cartItem = this.cart.find((item) => item.productId === this.product.id);
      return cartItem ? cartItem.quantity : 0;
    },
  },
  methods: {
    getFullImagePath(imagePath) {
      return `${window.location.origin}${imagePath}`;
    },
    handleImageClick(event) {
      const clickX = event.clientX;
      const imageWidth = event.target.clientWidth;
      if (clickX < imageWidth / 2) {
        this.prevImage();
      } else {
        this.nextImage();
      }
    },
    async fetchProduct(id) {
      try {
        const response = await axios.get(`/api/products/${id}`);
        this.product = response.data;
      } catch (error) {
        console.error('Ошибка при загрузке продукта:', error);
      }
    },
    async fetchExchangeRates() {
      try {
        const response = await axios.get('/api/currency');
        this.exchangeRates = response.data.rates;
        this.selectedCurrencySymbol = this.getCurrencySymbol(this.selectedCurrency);
        this.convertCartTotal();
      } catch (error) {
        console.error('Ошибка при загрузке курсов валют:', error);
      }
    },
    getCurrencySymbol(currency) {
      switch (currency) {
        case 'RUB':
          return '₽';
        case 'USDT':
          return '₮';
        default:
          return '฿';
      }
    },
    convertCartTotal() {
      this.convertedCartTotal = Math.round(this.cartTotal * (this.exchangeRates[this.selectedCurrency] || 1));
    },
    async addToCart() {
      const sessionId = localStorage.getItem('sessionId') || 'default-session';
      try {
        const response = await axios.post('/api/cart', {
          sessionId,
          productId: this.product.id,
          quantity: 1,
        });
        if (response.data && this.product.priceTHB) {
          this.cart.push({ productId: this.product.id, quantity: 1, product: this.product });
          localStorage.setItem('cart', JSON.stringify(this.cart));
          this.updateCartTotal();
          this.$emit('cartUpdated');
        }
      } catch (error) {
        console.error('Ошибка при добавлении товара в корзину:', error);
      }
    },
    async increaseQuantity() {
      const sessionId = localStorage.getItem('sessionId') || 'default-session';
      const cartItem = this.cart.find((item) => item.productId === this.product.id);
      if (cartItem) {
        try {
          cartItem.quantity += 1;
          await axios.post('/api/cart', {
            sessionId,
            productId: this.product.id,
            quantity: cartItem.quantity,
          });
          localStorage.setItem('cart', JSON.stringify(this.cart));
          this.updateCartTotal();
          this.$emit('cartUpdated');
        } catch (error) {
          console.error('Ошибка при увеличении количества товара:', error);
        }
      }
    },
    async decreaseQuantity() {
      const sessionId = localStorage.getItem('sessionId') || 'default-session';
      const cartItem = this.cart.find((item) => item.productId === this.product.id);
      if (cartItem && cartItem.quantity > 1) {
        try {
          cartItem.quantity -= 1;
          await axios.post('/api/cart', {
            sessionId,
            productId: this.product.id,
            quantity: cartItem.quantity,
          });
          localStorage.setItem('cart', JSON.stringify(this.cart));
          this.updateCartTotal();
          this.$emit('cartUpdated');
        } catch (error) {
          console.error('Ошибка при уменьшении количества товара:', error);
        }
      } else if (cartItem && cartItem.quantity === 1) {
        this.removeFromCart();
      }
    },
    async removeFromCart() {
      const sessionId = localStorage.getItem('sessionId') || 'default-session';
      try {
        await axios.delete('/api/cart/remove', {
          data: {
            sessionId,
            productId: this.product.id,
          },
        });
        this.cart = this.cart.filter((item) => item.productId !== this.product.id);
        localStorage.setItem('cart', JSON.stringify(this.cart));
        this.updateCartTotal();
        this.$emit('cartUpdated');
      } catch (error) {
        console.error('Ошибка при удалении товара из корзины:', error);
      }
    },
    async fetchCart() {
      const sessionId = localStorage.getItem('sessionId') || 'default-session';
      try {
        const response = await axios.get('/api/cart', { params: { sessionId } });
        if (response.data && response.data.items) {
          this.cart = response.data.items;
          this.updateCartTotal();
        } else {
          this.cart = [];
        }
      } catch (error) {
        console.error('Ошибка при загрузке корзины:', error);
      }
    },
    updateCartTotal() {
      this.cartTotal = this.cart.reduce((total, item) => total + (item.product?.priceTHB || 0) * item.quantity, 0);
      this.convertCartTotal();
    },
    goToCart() {
      this.$router.push('/cart');
    },
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.product.photos.length;
    },
    prevImage() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.product.photos.length) % this.product.photos.length;
    },
    closeModal() {
      this.$emit('close');
      this.$emit('cartUpdated');
    },
  },
  watch: {
    productId(newId) {
      this.fetchProduct(newId);
    },
    isVisible(newVisibility) {
      if (newVisibility) {
        this.selectedCurrency = localStorage.getItem('selectedCurrency') || 'THB';
        this.fetchExchangeRates();
        this.fetchCart();
      }
    },
  },
  mounted() {
    this.fetchProduct(this.productId);
    this.fetchExchangeRates();
    this.fetchCart();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #e2e8e4;
  color: rgb(0, 108, 132);
  padding: 40px;
  border-radius: 8px;
  width: 90%;
  max-width: 1000px;
  max-height: 80vh;
  overflow-y: auto;
}

.product-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-name {
  font-size: 2rem;
  margin: 0 25px 20px;
  text-align: left;
  width: calc(100% - 50px);
}

.product-images {
  position: relative;
  margin: 0 15px 20px;
  width: calc(100% - 30px);
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator-dot {
  width: 8px;
  height: 8px;
  background-color: lightgray;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicator-dot.active {
  background-color: rgb(0, 108, 132);
}

.product-actions {
  display: flex;
  justify-content: space-between;
  margin: 0 25px 20px;
  width: calc(100% - 50px);
}

.product-price {
  font-size: 1.5rem;
  font-weight: bold;
}

.cart-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-btn {
  background-color: rgba(110, 181, 192, 0);
  color: rgb(0, 108, 132);
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quantity-value {
  font-size: 1rem;
}

.cart-btn {
  background-color: rgb(0, 108, 132);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.fixed-cart-button {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(110, 181, 192, 0.1);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
  border: 1px solid white;
}

.fixed-cart-button:hover {
  background-color: rgb(0, 108, 132, 0.8);
}

.cart-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.cart-total {
  font-size: 18px;
  font-weight: bold;
}

.product-description {
  font-size: 1rem;
  margin: 0 25px;
  text-align: left;
  width: calc(100% - 50px);
}

/* Мобильная версия */
@media (max-width: 768px) {
  .modal-content {
    padding: 20px;
  }
  .product-name,
  .product-description,
  .product-actions {
    margin: 0 10px;
    width: calc(100% - 20px);
  }
  .product-images {
    margin: 0 5px 20px;
    width: calc(100% - 10px);
  }
}
</style>