<template>
  <div class="faq-page">
    <!-- Меню -->
    <MenuComponent />

    <!-- Основной контент -->
    <div class="content">
      <h1>Часто задаваемые вопросы</h1>

      <div class="faq-section">
        <div v-for="(faq, index) in faqList" :key="index" class="faq-item">
          <button class="faq-question" @click="toggleAnswer(index)">
            {{ faq.question }}
            <span class="faq-icon">{{ activeIndex === index ? '-' : '+' }}</span>
          </button>
          <div v-if="activeIndex === index" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Кнопка наверх -->
    <button
      v-show="showScrollButton"
      class="scroll-to-top"
      @click="scrollToTop"
    >
      <img src="@/assets/uparrow.png" alt="Scroll to top" class="uparrow-icon" /> <!-- Иконка вверх -->
    </button>

    <!-- Футер -->
    <footer class="footer">
      <p>© 2024 "1-THAI.STORE". Все права защищены.</p> <!-- Динамически переводим фразу для футера -->
    </footer>
  </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import throttle from 'lodash.throttle';

export default {
  components: {
    MenuComponent,
  },
  data() {
    return {
      activeIndex: null, // Индекс активного (раскрытого) вопроса
      faqList: [
        {
          question: 'Как сделать заказ?',
          answer: 'Для того чтобы сделать заказ, выберите товар на странице магазина и добавьте его в корзину. Затем перейдите в корзину и оформите заказ. После оформления заказа менеджер свяжется с Вами в ближайшее время',
        },
        {
          question: 'Какие способы оплаты вы поддерживаете?',
          answer: 'Мы принимаем переводы на российские и тайские банковские карты, а также криптовалютой.',
        },
      ],
      showScrollButton: false, // Управление показом кнопки прокрутки
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index; // Переключаем активный вопрос
    },
    handleScroll() {
      this.showScrollButton = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Плавная прокрутка вверх
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', throttle(this.handleScroll, 200));
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.faq-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('@/assets/overlay.jpg'); /* Фон как на других страницах */
  background-size: cover;
  background-position: center;
  padding: 20px;
  color: rgb(226, 232, 228);
}

.content {
  flex: 1;
  text-align: center;
  width: 80%;
  margin: 20px auto;
}

h1 {
  color: white;
  font-size: 36px;
  margin-bottom: 30px;
}

/* Стили для FAQ */
.faq-section {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 15px;
}

.faq-question {
  width: 100%;
  padding: 15px;
  text-align: left;
  background-color: rgb(110, 181, 192);
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question:hover {
  background-color: rgb(0, 108, 132);
}

.faq-answer {
  background-color: rgb(226, 232, 228, 0.8);
  color: black;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
}

.faq-icon {
  font-size: 24px;
  line-height: 24px;
}

/* Футер */
.footer {
  background-color: #6eb5c0;
  color: #ffffff;
  text-align: center;
  width: 100%; /* Занимает всю ширину экрана */
  height: 40px; /* Высота футера */
  display: flex; /* Используем flexbox */
  justify-content: center; /* Центрируем по горизонтали */
  align-items: center; /* Центрируем по вертикали */
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: auto; /* Чтобы футер не накладывался на контент */
  z-index: 9990;
}

.scroll-to-top {
  position: fixed;
  bottom: 80px; /* Расположение над футером */
  right: 40px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 99;
}

.scroll-to-top .uparrow-icon {
  width: 40px;
  height: 40px;
}

.scroll-to-top:hover .uparrow-icon {
  transform: scale(1.1); /* Увеличение картинки при наведении */
  transition: transform 0.2s;
}
</style>