<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">X</button>
      <p>{{ formatDate(news.publishedAt) }}</p>
      <h2>{{ news.title_ru }}</h2>
      <div class="news-content">
        <!-- Используем v-html для рендеринга HTML в контенте новости -->
        <p v-html="formattedContent"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    news: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedContent() {
      // Заменяем переносы строк на HTML-разрывы строк
      return this.news.content_ru
        ? this.news.content_ru.replace(/\n/g, '<br>')
        : '';
    }
  },
  watch: {
    isVisible(newVal) {
      document.body.style.overflow = newVal ? 'hidden' : 'auto';
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('ru-RU', options);
    }
  },
  beforeUnmount() {
    document.body.style.overflow = 'auto';
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  overflow: hidden;
}

.modal-content {
  background-color: #e2e8e4;
  color: black;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.news-content {
  margin-top: 20px;
  white-space: pre-wrap; /* Сохраняет пробелы и переносы строк */
}

@media (max-width: 768px) {
  .modal-content {
    max-height: 85vh;
    padding: 40px;
  }
}
</style>