import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import vClickOutside from 'v-click-outside';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import i18n from './i18n';

// Добавляем иконки в библиотеку FontAwesome
library.add(faArrowUp);

// Функция для генерации уникального sessionId
function generateSessionId() {
  return 'session-' + Math.random().toString(36).substr(2, 9);
}

// Проверяем наличие sessionId в localStorage
let sessionId = localStorage.getItem('sessionId');
if (!sessionId) {
  sessionId = generateSessionId(); // Генерация sessionId
  localStorage.setItem('sessionId', sessionId); // Сохранение sessionId в localStorage
}
console.log('Текущая сессия:', sessionId);  // Логируем для проверки

// Создаем приложение
const app = createApp(App);

// Регистрация компонента FontAwesome глобально
app.component('font-awesome-icon', FontAwesomeIcon);

// Настройка базового URL для запросов на бэкенд
axios.defaults.baseURL = 'https://1-thai.store/';

// Интерсептор для автоматической отправки токена и sessionId
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    console.log('Отправляем запрос с токеном:', token);  // Логируем токен для отладки
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Добавляем токен в заголовок Authorization
    }

    // Добавляем sessionId в заголовки запросов, кроме запросов к /uploads
    if (!config.url.startsWith('/uploads')) {
      config.headers['Session-ID'] = sessionId;  // Передаем sessionId с каждым API-запросом
    }
    console.log('Заголовки перед отправкой:', config.headers);
    return config;
  },
  (error) => {
    console.error('Ошибка при отправке запроса:', error);  // Логируем ошибку при отправке
    return Promise.reject(error);
  }
);

// Интерсептор для обработки ошибок (например, 401 Unauthorized)
axios.interceptors.response.use(
  (response) => {
    console.log('Ответ от сервера:', response.data);  // Логируем успешный ответ
    return response;
  },
  (error) => {
    console.error('Ошибка в ответе от сервера:', error.response ? error.response.data : error.message);  // Логируем ошибку ответа
    if (error.response && error.response.status === 401) {
      // Если ошибка 401 (не авторизован), перенаправляем на страницу логина
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

// Сделаем Axios доступным во всех компонентах как this.$axios
app.config.globalProperties.$axios = axios;

// Подключение v-click-outside для глобального использования
app.use(vClickOutside);

// Подключение i18n для перевода
app.use(i18n);

// Подключение маршрутов
app.use(router);

// Монтируем приложение на элемент с id "app"
app.mount('#app');