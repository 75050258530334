<template>
  <div v-if="isAuthorized" class="admin-product-page">
    <h1 class="page-title">Административная панель</h1>

    <button class="back-button" @click="goBackToAdminPage">Вернуться к AdminPage</button>
    <MenuComponent />

    <section>
      <h2>Продукты</h2>
      <table v-if="products.length > 0">
        <thead>
          <tr>
            <th>№</th>
            <th>Тип продукта</th>
            <th>Название</th>
            <th>Языки</th>
            <th>Цена</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in products" :key="product.id">
            <td>{{ index + 1 }}</td>
            <td>{{ product.productType || 'Тип не указан' }}</td>
            <td>{{ product.nameRu || 'Название отсутствует' }}</td>
            <td>{{ product.hasEnglish ? 'RU/EN' : 'RU' }}</td>
            <td>{{ product.priceTHB }} THB</td>
            <td>
              <button class="edit-btn" @click="openEditModal(product)">✎</button>
              <button class="delete-btn" @click="deleteProduct(product.id)">&#10006;</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>Продукты не найдены.</p>
    </section>

    <section>
      <h2>Новости</h2>
      <table v-if="news.length > 0">
        <thead>
          <tr>
            <th>№</th>
            <th>Заголовок</th>
            <th>Дата публикации</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(article, index) in news" :key="article.id">
            <td>{{ index + 1 }}</td>
            <td>{{ article.title_ru || 'Заголовок отсутствует' }}</td>
            <td>{{ new Date(article.publishedAt).toLocaleDateString() }}</td>
            <td>
              <button class="edit-btn" @click="openNewsEditModal(article)">✎</button>
              <button class="delete-btn" @click="deleteNews(article.id)">&#10006;</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>Новости не найдены.</p>
    </section>

    <EditItemModal
      v-if="editModalOpen"
      :isOpen="editModalOpen"
      :product="selectedProduct"
      @save="handleEditProduct"
      @close="closeEditModal"
    />
    <EditNewsModal
      v-if="editNewsModalOpen"
      :isOpen="editNewsModalOpen"
      :article="selectedArticle"
      @save="handleEditNews"
      @close="closeNewsEditModal"
    />
  </div>
  <p v-else>Вы не авторизованы для доступа к этой странице</p>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
import EditItemModal from './EditItemModal.vue';
import EditNewsModal from './EditNewsModal.vue';

export default {
  components: {
    MenuComponent,
    EditItemModal,
    EditNewsModal,
  },
  data() {
    return {
      isAuthorized: false,
      products: [],
      news: [],
      editModalOpen: false,
      editNewsModalOpen: false,
      selectedProduct: null,
      selectedArticle: null,
    };
  },
  methods: {
    goBackToAdminPage() {
      this.$router.push('/admin/dashboard');
    },
    async checkAuthorization() {
      const token = localStorage.getItem('authToken');
      if (!token) {
        this.$router.push('/login');
      } else {
        try {
          const response = await this.$axios.get('/api/check-admin', {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.isAuthorized = response.data.message === 'Пользователь является администратором';

          if (this.isAuthorized) {
            this.fetchProducts();
            this.fetchNews();
          } else {
            this.$router.push('/login');
          }
        } catch (error) {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      }
    },
    async fetchProducts() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await this.$axios.get('/api/products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.products = response.data;
        console.log('Полученные продукты:', this.products);
      } catch (error) {
        console.error('Ошибка при получении продуктов:', error);
      }
    },
    async fetchNews() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await this.$axios.get('/api/news', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.news = response.data;
        console.log('Полученные новости:', this.news);
      } catch (error) {
        console.error('Ошибка при получении новостей:', error);
      }
    },
    openEditModal(product) {
      this.selectedProduct = { ...product };
      this.editModalOpen = true;
    },
    openNewsEditModal(article) {
      this.selectedArticle = { ...article };
      this.editNewsModalOpen = true;
    },
    closeEditModal() {
      this.editModalOpen = false;
    },
    closeNewsEditModal() {
      this.editNewsModalOpen = false;
    },
    async handleEditProduct() {
      try {
        await this.fetchProducts();
        this.closeEditModal();
      } catch (error) {
        console.error('Ошибка при редактировании продукта:', error);
      }
    },
    async handleEditNews(updatedNews) {
      try {
        await this.$axios.put(`/api/news/${updatedNews.id}`, updatedNews);
        this.fetchNews();
        this.closeNewsEditModal();
      } catch (error) {
        console.error('Ошибка при редактировании новости:', error);
      }
    },
    async deleteProduct(productId) {
      if (!productId) {
        console.error('ID продукта отсутствует!');
        return;
      }

      try {
        const token = localStorage.getItem('authToken');
        await this.$axios.delete(`/api/products/${productId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.fetchProducts();
      } catch (error) {
        console.error('Ошибка при удалении продукта:', error.response ? error.response.data : error.message);
      }
    },
    async deleteNews(id) {
      try {
        await this.$axios.delete(`/api/news/${id}`);
        this.fetchNews();
      } catch (error) {
        console.error('Ошибка при удалении новости:', error);
      }
    }
  },
  mounted() {
    this.checkAuthorization();
  }
};
</script>

<style scoped>
/* Стили для элементов страницы */
.admin-product-page {
  background-color: #121212;
  color: white;
  padding: 20px;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
}

.back-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #3eda17;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #36648b;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto 40px auto;
}

th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid white;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

button:hover {
  background-color: #45a049;
}

h1, h2, h3 {
  color: white;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.edit-btn {
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-btn:hover {
  background-color: #45a049;
}

.delete-btn {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #e53935;
}
</style>