<template>
  <!-- Отображаем кнопку корзины только если в корзине есть товары -->
  <div v-if="cartTotal > 0" class="cart-button" @click="goToCart">
    <img class="cart-image" src="@/assets/cart.png" alt="Корзина" />
    <span class="cart-total">{{ convertedCartTotal }} {{ selectedCurrencySymbol }}</span>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/eventBus'; // Используем для изменений корзины

export default {
  data() {
    return {
      cartTotal: 0, // Общая сумма товаров в корзине в THB
      convertedCartTotal: 0, // Сумма товаров в корзине с учетом выбранной валюты
      selectedCurrency: 'THB', // Валюта по умолчанию
      selectedCurrencySymbol: '฿', // Символ выбранной валюты
      exchangeRates: { RUB: 1, USDT: 1 }, // Курсы обмена валют
    };
  },
  mounted() {
    this.loadCurrencyFromLocalStorage();
    this.fetchExchangeRates(); // Загружаем курсы валют
    this.fetchCart(); // Загружаем корзину при монтировании компонента

    // Слушаем события EventBus для изменений корзины
    eventBus.on('cartUpdated', this.fetchCart);

    // Добавляем слушатель для изменения валюты в LocalStorage
    window.addEventListener('storage', this.handleStorageChange);
  },
  beforeUnmount() {
    // Убираем слушатели при уничтожении компонента
    eventBus.off('cartUpdated', this.fetchCart);
    window.removeEventListener('storage', this.handleStorageChange);
  },
  methods: {
    // Обработчик изменения LocalStorage для валюты
    handleStorageChange(event) {
      if (event.key === 'selectedCurrency') {
        this.setCurrency(event.newValue);
      }
    },

    // Загрузка выбранной валюты из LocalStorage
    loadCurrencyFromLocalStorage() {
      const savedCurrency = localStorage.getItem('selectedCurrency') || 'THB';
      this.setCurrency(savedCurrency);
    },

    // Метод для изменения валюты
    setCurrency(currency) {
      this.selectedCurrency = currency;
      switch (currency) {
        case 'RUB':
          this.selectedCurrencySymbol = '₽';
          break;
        case 'USDT':
          this.selectedCurrencySymbol = '₮';
          break;
        default:
          this.selectedCurrencySymbol = '฿';
      }
      this.convertCartTotal(); // Конвертируем сумму корзины
    },

    // Получение курсов обмена валют с бэкенда
    async fetchExchangeRates() {
      try {
        const response = await axios.get('/api/currency');
        if (response.data && response.data.rates) {
          this.exchangeRates.RUB = response.data.rates.RUB || 1;
          this.exchangeRates.USDT = response.data.rates.USDT || 1;
        }
      } catch (error) {
        console.error('Ошибка при загрузке курсов валют:', error.message);
      }
    },

    // Получение корзины с backend
    async fetchCart() {
      let sessionId = localStorage.getItem('sessionId'); // Получаем sessionId
      if (!sessionId) {
        sessionId = this.generateSessionId(); // Генерация нового sessionId, если его нет
        localStorage.setItem('sessionId', sessionId); // Сохранение sessionId в LocalStorage
      }

      try {
        const response = await axios.get('/api/cart', {
          params: { sessionId }, // Передача sessionId в параметрах запроса
        });
        if (response.data && response.data.totalPriceTHB !== undefined) {
          this.cartTotal = response.data.totalPriceTHB;
          this.convertCartTotal(); // Конвертируем сумму корзины после загрузки
        } else {
          this.cartTotal = 0;
        }
      } catch (error) {
        console.error('Ошибка при получении корзины:', error.message);
        this.cartTotal = 0;
      }
    },

    // Конвертация общей суммы корзины в выбранную валюту
    convertCartTotal() {
      if (!this.exchangeRates || !this.selectedCurrency) {
        this.convertedCartTotal = this.cartTotal;
        return;
      }

      switch (this.selectedCurrency) {
        case 'RUB':
          this.convertedCartTotal = Math.round(this.cartTotal * this.exchangeRates.RUB);
          break;
        case 'USDT':
          this.convertedCartTotal = Math.round(this.cartTotal * this.exchangeRates.USDT);
          break;
        default:
          this.convertedCartTotal = Math.round(this.cartTotal);
      }
    },

    // Переход на страницу корзины
    goToCart() {
      this.$router.push('/cart');
    },

    // Генерация уникального sessionId
    generateSessionId() {
      return 'sess_' + Math.random().toString(36).substring(2);
    },
  },
};
</script>

<style scoped>
.cart-button {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: rgb(110, 181, 192, 0.1);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
  border: 1px solid white;
}

.cart-button:hover {
  background-color: rgb(0, 108, 132, 0.8);
}

.cart-image {
  width: 20px; /* Уменьшение размера иконки */
  height: 20px;
  margin-right: 5px; /* Уменьшение отступа между иконкой и текстом */
}

.cart-total {
  font-size: 18px; /* Уменьшение размера шрифта */
  font-weight: bold;
}
</style>