<template>
  <div class="menu-wrapper">
    <!-- Кнопка гамбургер-меню -->
    <button class="menu-button" @click="toggleMenu">
      <div class="hamburger"></div>
      <div class="hamburger"></div>
      <div class="hamburger"></div>
    </button>

    <!-- Меню -->
    <nav v-if="menuOpen" class="side-menu" @click.stop>
      <ul>
        <li><router-link to="/">{{ $t('home') }}</router-link></li>
        <li><router-link to="/catalog">{{ $t('catalog') }}</router-link></li>
        
        <!-- Отображаем пункт меню "Новости" только если выбран русский язык -->
        <li v-if="selectedLanguage === 'ru'"><router-link to="/news">{{ $t('news') }}</router-link></li>
        <li><router-link to="/faq">F.A.Q.</router-link></li>
        <li><router-link to="/contacts">{{ $t('contacts') }}</router-link></li>
        <!-- Блок для смены языка -->
        <li v-if="isLanguageSwitcherVisible">
          <div class="language-switch">
            <button 
              @click="changeLanguage('ru')" 
              class="language-button" 
              :class="{ active: selectedLanguage === 'ru' }"
            >
              <img src="@/assets/ru.png" alt="Русский" />
            </button>
            <button 
              @click="changeLanguage('en')" 
              class="language-button" 
              :class="{ active: selectedLanguage === 'en' }"
            >
              <img src="@/assets/en.png" alt="English" />
            </button>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false, // Управление состоянием меню (открыто/закрыто)
      selectedLanguage: localStorage.getItem('selectedLanguage') || 'ru', // По умолчанию выбран русский язык
    };
  },
  computed: {
    // Определяем, отображать ли переключатель языка
    isLanguageSwitcherVisible() {
      const currentPath = this.$route.path;
      return currentPath === '/' || currentPath === '/contacts';
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    changeLanguage(language) {
      this.selectedLanguage = language;
      localStorage.setItem('selectedLanguage', language);
      this.$i18n.locale = language;
    },
    closeMenu() {
      this.menuOpen = false; // Закрываем меню
    },
    handleOutsideClick(event) {
      // Если клик был вне меню и меню открыто, закрываем его
      if (this.menuOpen && !this.$el.contains(event.target)) {
        this.closeMenu();
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick); // Добавляем обработчик клика на документ

    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      this.$i18n.locale = savedLanguage;
    }
  },
  beforeUnmount() { // Заменяем на beforeUnmount
    document.removeEventListener('click', this.handleOutsideClick); // Убираем обработчик клика при уничтожении компонента
  }
};
</script>

<style scoped>
/* Кнопка гамбургер-меню */
.menu-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  z-index: 1101;
}

.hamburger {
  width: 30px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 2px;
}

.side-menu {
  position: fixed; /* Изменено с absolute на fixed */
  top: 0;
  left: 0;
  height: 100vh; /* Обеспечиваем полную высоту экрана */
  width: 140px;
  background-color: rgba(0, 108, 132, 0.9);
  z-index: 1100; /* Должен быть достаточно высоким для перекрытия футера */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 80px;
  overflow-y: auto; /* Позволяет прокручивать меню при необходимости */
}

.side-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.side-menu li {
  margin-bottom: 20px;
}

.side-menu a {
  color: #fcfcfc;
  text-decoration: none;
  font-size: 18px;
  padding-left: 20px;
  font-weight: bold;
}

.language-switch {
  display: flex;
  gap: 20px;
  padding-left: 20px;
}

.language-button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
}

.language-button img {
  display: block;
  width: 25px;
  height: 25px;
}

.language-button.active img {
  box-shadow: 0 0 30px #ffffff;
  border-radius: 20%;
  border: 1px solid rgb(255, 204, 187);
}
</style>