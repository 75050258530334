import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import CatalogPage from '@/components/CatalogPage.vue';
import CartPage from '@/components/CartPage.vue';
import NewsPage from '@/components/NewsPage.vue';
import ContactsPage from '@/components/ContactsPage.vue';
import FaqPage from '@/components/FaqPage.vue'; 
import LoginPage from '@/components/LoginPage.vue';
import AdminPage from '@/components/AdminPage.vue';
import AdminProductPage from '@/components/AdminProductPage.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/catalog', component: CatalogPage },
  { path: '/cart', component: CartPage },
  { path: '/news', component: NewsPage },
  { path: '/contacts', component: ContactsPage },
  { path: '/faq', component: FaqPage },
  { path: '/login', component: LoginPage },
  {
    path: '/admin/dashboard',
    component: AdminPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/products',
    component: AdminProductPage,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Проверка авторизации перед переходом на защищенные маршруты
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken'); // Изменили на 'authToken'

  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next('/login'); // Если нет токена, перенаправляем на страницу логина
  } else {
    next(); // Все нормально, продолжаем
  }
});

export default router;