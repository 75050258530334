<template>
  <div v-if="isAuthorized" class="admin-page">
    <!-- Кнопка выхода из аккаунта -->
    <button class="logout-button" @click="logout">Выйти</button>

    <!-- Встроим компонент меню -->
    <MenuComponent />

    <h1 class="admin-title">Административная панель</h1>

    <!-- Секция управления курсами валют -->
    <section>
      <div class="currency-management">
        <div class="currency-box">
          <div class="currency-left">
            <p class="currency-rate">RUB: {{ rates.RUB }}</p>
            <input v-model="newRUBRate" class="currency-input" placeholder="Новый курс RUB" />
            <button @click="updateCurrencyRate('RUB')" class="currency-button">Обновить курс RUB</button>
          </div>
          <div class="currency-right">
            <p class="currency-rate">USDT: {{ rates.USDT }}</p>
            <input v-model="newUSDTRate" class="currency-input" placeholder="Новый курс USDT" />
            <button @click="updateCurrencyRate('USDT')" class="currency-button">Обновить курс USDT</button>
          </div>
        </div>
      </div>
    </section>

    <!-- Кнопки перехода к управлению товарами, созданию продуктов и новостей -->
    <section class="actions">
      <div class="action-buttons">
        <button class="primary-button create-product-button" @click="openCreateModal">Создать продукт</button>
        <button class="primary-button news-button" @click="openNewsModal">Создать новость</button>
        <button class="primary-button product-button" @click="goToProducts">Управление контентом</button>
      </div>
    </section>

    <!-- Модальное окно для создания продукта -->
    <AddItemModal
      v-if="modalOpen"
      :isOpen="modalOpen"
      @save="handleCreate"
      @close="closeModal"
    />

    <!-- Модальное окно для создания новости -->
    <AddNewsModal
      v-if="newsModalOpen"
      :isVisible="newsModalOpen"
      @close="closeNewsModal"
      @newsAdded="refreshNews"
    />

    <!-- Секция управления пользователями -->
    <section>
      <h2 class="user-management-title">Управление пользователями</h2>
      <table>
        <thead>
          <tr>
            <th>Имя</th>
            <th>Email</th>
            <th>Роль</th>
            <th class="actions-header">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.isAdmin ? 'Админ' : 'Пользователь' }}</td>
            <td class="actions-cell">
              <button @click="toggleAdmin(user)">
                {{ user.isAdmin ? 'Разжаловать' : 'Назначить админом' }}
              </button>
              <button @click="deleteUser(user.id)">Удалить</button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>

  <!-- Сообщение для неавторизованных пользователей -->
  <p v-else>Вы не авторизованы для доступа к этой странице</p>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import AddItemModal from './AddItemModal.vue';
import AddNewsModal from './AddNewsModal.vue';

export default {
  components: {
    MenuComponent,
    AddItemModal,
    AddNewsModal,
  },
  data() {
    return {
      isAuthorized: false,
      rates: { RUB: 0, USDT: 0 },
      newRUBRate: '',
      newUSDTRate: '',
      users: [],
      modalOpen: false,
      newsModalOpen: false,
    };
  },
  methods: {
    async checkAuthorization() {
      const token = localStorage.getItem('token');
      if (!token) {
        this.$router.push('/login');
      } else {
        try {
          const response = await this.$axios.get('/api/check-admin', {
            headers: { Authorization: `Bearer ${token}` }
          });

          this.isAuthorized = response.data.message === 'Пользователь является администратором';

          if (this.isAuthorized) {
            await this.fetchCurrencyRates();
            await this.fetchUsers();
          } else {
            this.$router.push('/login');
          }
        } catch (error) {
          console.error('Ошибка при проверке авторизации:', error);
          this.$router.push('/login');
        }
      }
    },

    async fetchCurrencyRates() {
      const token = localStorage.getItem('token');
      try {
        const response = await this.$axios.get('/api/currency', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.rates = response.data.rates;
      } catch (error) {
        console.error('Ошибка при получении курсов валют:', error);
      }
    },

    async updateCurrencyRate(currency) {
      const token = localStorage.getItem('token');
      try {
        const rate = currency === 'RUB' ? this.newRUBRate : this.newUSDTRate;
        await this.$axios.put('/api/currency/update', { [currency]: rate }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.fetchCurrencyRates();
        alert(`Курс ${currency} обновлён!`);
      } catch (error) {
        console.error(`Ошибка при обновлении курса ${currency}:`, error);
      }
    },

    async fetchUsers() {
      const token = localStorage.getItem('token');
      try {
        const response = await this.$axios.get('/api/users', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.users = response.data;
      } catch (error) {
        console.error('Ошибка при получении пользователей:', error);
      }
    },

    async toggleAdmin(user) {
      const token = localStorage.getItem('token');
      try {
        const updatedUser = await this.$axios.put(`/api/users/${user.id}/make-admin`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
        user.isAdmin = updatedUser.data.isAdmin;
        this.$forceUpdate();
      } catch (error) {
        console.error('Ошибка при изменении роли пользователя:', error);
      }
    },

    async deleteUser(userId) {
      const token = localStorage.getItem('token');
      try {
        await this.$axios.delete(`/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.users = this.users.filter(user => user.id !== userId);
      } catch (error) {
        console.error('Ошибка при удалении пользователя:', error);
      }
    },

    openCreateModal() {
      this.modalOpen = true;
    },

    closeModal() {
      this.modalOpen = false;
    },

    openNewsModal() {
      this.newsModalOpen = true;
    },

    closeNewsModal() {
      this.newsModalOpen = false;
    },

    async logout() {
      try {
        await this.$axios.post('/api/users/logout');
        localStorage.removeItem('token');
        this.$router.push('/login');
      } catch (error) {
        console.error('Ошибка при выходе из аккаунта:', error);
      }
    },

    goToProducts() {
      this.$router.push('/admin/products');
    }
  },

  mounted() {
    this.checkAuthorization();
  }
};
</script>

<style scoped>
.admin-page {
  background-color: #121212;
  color: white;
  padding: 20px;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-title,
.user-management-title {
  text-align: center;
  color: #ffffff;
  margin: 20px 0;
}

.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #fe826c;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.logout-button:hover {
  background-color: #e53e3e;
}

.currency-management {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
}

.currency-box {
  background-color: #8aff5c;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.currency-left,
.currency-right {
  width: 200px;
  text-align: center;
}

.currency-rate {
  font-size: 28px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}

.currency-input {
  padding: 10px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  width: 100%;
  background-color: #1e1e1e;
  color: white;
  text-align: center;
}

.currency-button {
  padding: 10px 0;
  width: 100%;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.currency-button:hover {
  background-color: #fe826c;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.primary-button {
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  background-color: #4A90E2;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.primary-button:hover {
  background-color: #316fad;
}

table {
  width: 80%;
  border-collapse: collapse;
  margin: 0 auto 40px auto;
}

th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid white;
}
</style>