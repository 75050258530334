<template>
  <div class="contact-page">
    <!-- Встроим компонент меню -->
    <MenuComponent />

    <!-- Встроим компонент корзины -->
    <CartComponent class="cart-component" />

    <!-- Содержимое страницы контактов -->
    <div class="content">
      <h1>{{ $t('contactsTitle') }}:</h1> <!-- Перевод заголовка -->
      <div class="contact-info">
        <p><strong>Telegram:</strong></p>
        <p><a href="https://t.me/rasthai_store" target="_blank">https://t.me/rasthai_store</a></p>
        <p><strong>Email:</strong></p>
        <p><a href="mailto:rasthai.store@yandex.com">rasthai.store@yandex.com</a></p>
      </div>
    </div>

    <!-- Футер -->
    <footer class="footer">
      <p>{{ $t('footerRights') }}</p> <!-- Динамический перевод фразы для футера -->
    </footer>
  </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent.vue';
import CartComponent from '@/components/CartComponent.vue';

export default {
  components: {
    MenuComponent,
    CartComponent,
  },
};
</script>

<style scoped>
.contact-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('@/assets/overlay.jpg');
  background-size: cover;
  background-position: center;
  color: #6eb5c0;
}

h1 {
  color: #ffffff;
  text-align: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
}

.contact-info p {
  font-size: 18px;
  margin: 5px 0; /* Отступ между строками */
}

.contact-info a {
  font-size: 18px;
  color: #e2e8e4;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Мобильная версия */
@media (max-width: 768px) {
  .contact-info p,
  .contact-info a {
    font-size: 16px;
    text-align: center;
  }
}

.footer {
  background-color: #6eb5c0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Убедитесь, что футер имеет relative */
  bottom: 0;
  left: 0;
  z-index: 9990;
}
</style>