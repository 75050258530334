<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <h2>Добавить новость</h2>
      <form @submit.prevent="submitNews">
        <!-- Поле для заголовка новости -->
        <div class="form-group">
          <label for="title">Заголовок</label>
          <input type="text" id="title" v-model="newsData.title_ru" required />
        </div>

        <!-- Поле для контента новости -->
        <div class="form-group">
          <label for="content">Контент</label>
          <textarea id="content" v-model="newsData.content_ru" required></textarea>
        </div>

        <!-- Поле для даты публикации -->
        <div class="form-group">
          <label for="publishedAt">Дата публикации</label>
          <input type="date" id="publishedAt" v-model="newsData.publishedAt" required />
        </div>

        <!-- Кнопки действия -->
        <div class="form-actions">
          <button type="submit" class="submit-btn">Добавить</button>
          <button type="button" class="cancel-btn" @click="closeModal">Отмена</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddNewsModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newsData: {
        title_ru: '',  // Изменили на title_ru
        content_ru: '',  // Изменили на content_ru
        publishedAt: '',
      },
    };
  },
  methods: {
    async submitNews() {
      try {
        // Отправляем данные на сервер для создания новости
        const response = await axios.post('/api/news', {
          title_ru: this.newsData.title_ru,  // Используем title_ru
          content_ru: this.newsData.content_ru,  // Используем content_ru
          publishedAt: this.newsData.publishedAt,
        });

        console.log('Новость добавлена:', response.data);

        // Закрываем модальное окно и очищаем поля
        this.closeModal();
        this.clearForm();

        // Сообщаем об успешном добавлении новости
        this.$emit('newsAdded', response.data);
      } catch (error) {
        console.error('Ошибка при добавлении новости:', error.response ? error.response.data : error.message);
      }
    },
    closeModal() {
      this.$emit('close'); // Закрываем модальное окно через событие
    },
    clearForm() {
      this.newsData = {
        title_ru: '',
        content_ru: '',
        publishedAt: '',
      };
    },
  },
};
</script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90vw;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-btn:hover {
    background-color: #c82333;
  }
  </style>